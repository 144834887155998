import React from "react";
import styled from "styled-components";

type ProgressBarProps = {
  value: number;
  max: number;
  width: string;
  color: string;
};

const ProgressText = styled.span``;

const Container = styled.div<{ width: string; color: string }>`
  text-align: center;

  progress[value] {
    width: ${(props: any) => props.width};
    height: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    color: blue;
  }

  progress[value]::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  }

  progress[value]::-webkit-progress-value {
    background-color: #3154bb;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  }
`;

const ProgressBar = ({ value, max, width, color }: ProgressBarProps) => {
  return (
    <Container width={width} color={color}>
      <progress value={value} max={max} />
      <ProgressText>{`${value} of ${max}`}</ProgressText>
    </Container>
  );
};

export default ProgressBar;
