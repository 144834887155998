import { createGlobalStyle } from "styled-components";
import Color from "color";

import "primereact/resources/themes/tailwind-light/theme.css";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

// COLORS
const COLOR_WHITE = "#ffffff";
const COLOR_DARK_BLUE = "#3154bb";
const COLOR_REGULAR_GREY = "#708096";
const COLOR_SOFT_GREY = "#f7f9fa";
const COLOR_LIGHT_GREY = "#eceef2";
const COLOR_CHARCOAL = "#37393f";

// SPACING
const SPACING_XSMALL = "1px";
const SPACING_SMALL = "4px";
const SPACING_MEDIUM = "8px";
const SPACING_LARGE = "12px";
const SPACING_XLARGE = "16px";
const SPACING_XXLARGE = "24px";

const theme = {
  link: {
    color: COLOR_CHARCOAL,
    active: COLOR_CHARCOAL,
  },
  font: {
    primary: "Roboto",
    colors: {
      error: COLOR_DARK_BLUE,
    },
  },
  flash: {
    colors: {
      primary: {
        background: COLOR_REGULAR_GREY,
        foreground: COLOR_WHITE,
      },
    },
  },
  card: {
    borderColor: Color(COLOR_REGULAR_GREY).lighten(0.7).hex(),
    subHeadingText: {
      color: COLOR_REGULAR_GREY,
    },
  },
  descriptionList: {
    primaryBackgroundColor: COLOR_WHITE,
    secondaryBackgroundColor: Color(COLOR_REGULAR_GREY).lighten(0.9).hex(),
    termColor: COLOR_REGULAR_GREY,
    descriptionColor: COLOR_CHARCOAL,
  },
  headings: {
    large: {
      fontSize: "2rem",
      marginTop: "8px",
      marginBottom: "16px",
    },
    medium: {
      fontSize: "1.5rem",
      marginTop: "8px",
      marginBottom: "16px",
      fontWeight: "300",
    },
    small: {
      fontSize: "1.1rem",
      marginTop: "8px",
      marginBottom: "10px",
    },
  },
  input: {
    borderColor: COLOR_REGULAR_GREY,
    padding: "16px",
    font: {
      size: "1rem",
    },
  },
  dropdown: {
    borderColor: "#000",
    hoverColor: "#eaf3f9",
  },
  checkbox: {
    focusColor: COLOR_REGULAR_GREY,
    iconColor: COLOR_REGULAR_GREY,
    size: "20px",
  },
  button: {
    padding: "12px 18px",
    colors: {
      disabled: {
        background: "#ccc",
      },
      primary: {
        background: COLOR_DARK_BLUE,
        text: COLOR_WHITE,
      },
      secondary: {
        background: COLOR_WHITE,
        text: COLOR_DARK_BLUE,
      },
    },
    border: {
      radius: "4px",
      color: COLOR_DARK_BLUE,
    },
    font: {
      default: {
        size: "12px",
      },
    },
  },
  page: {
    maxWidth: "960px",
    heading: {
      color: COLOR_CHARCOAL,
    },
    content: {
      padding: "0",
      backgroundColor: COLOR_LIGHT_GREY,
    },
    container: {
      backgroundColor: COLOR_WHITE,
      padding: "12px",
    },
  },
  breakpoints: {
    xsmall: "480px",
    small: "768px",
    medium: "1024px",
    large: "1280px",
  },
  spacing: {
    xsmall: SPACING_XSMALL,
    small: SPACING_SMALL,
    medium: SPACING_MEDIUM,
    large: SPACING_LARGE,
    xlarge: SPACING_XLARGE,
    xxlarge: SPACING_XXLARGE,
  },
  externalHeader: {
    backgroundColor: COLOR_WHITE,
    padding: "10px",
  },
  header: {
    backgroundColor: COLOR_WHITE,
    color: COLOR_CHARCOAL,
    borderColor: COLOR_SOFT_GREY,
  },
  footer: {
    backgroundColor: COLOR_WHITE,
    color: COLOR_WHITE,
  },
  sidebar: {
    backgroundColor: COLOR_WHITE,
    itemHighlightedColor: COLOR_SOFT_GREY,
    borderColor: COLOR_DARK_BLUE,
    color: COLOR_CHARCOAL,
  },
  table: {
    header: {
      backgroundColor: Color(COLOR_REGULAR_GREY).lighten(0.9).hex(),
      fontColor: COLOR_CHARCOAL,
    },
    colors: {
      border: "#ddd",
    },
    singleColumn: {
      colors: {
        fieldHeader: COLOR_REGULAR_GREY,
      },
    },
  },
};

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${theme.font.primary};
    line-height: initial;
  }

  body.ReactModal__Body--open {
    overflow: hidden;
  }

  a {
    color: ${theme.link.color};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;

export default theme;
