import React from "react";
import FileUpload from "components/FileUpload";
import styled from "styled-components";
import { Radio } from "sbui";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 33vw;
  padding-bottom: 10px;
`;

const RadioGroup = styled.div`
  input[type="radio"] {
  }
`;

const OptionsContainer = styled.div`
  margin: 10px;
`;

const DuplicateSection = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  ul {
    list-style: none;
    margin-left: 10px;
  }
`;

const ImportVerbatimsForm = ({
  organisationId,
  workspaceId,
  form,
  showOptions,
  duplicateSourceIdsInSystem,
  duplicateSourceIdsInFile,
}: any) => {
  const { errors } = form.formState;

  const onFileUpload = (upload: any) => {
    if (upload && upload.length > 0) {
      form.setValue("uploadKey", upload[0].meta.Key);
    }
  };

  let dupeInSystemLabel;
  if (duplicateSourceIdsInSystem.length > 0) {
    dupeInSystemLabel = (
      <DuplicateSection>
        <p>
          {duplicateSourceIdsInSystem.length} Duplicates found in the system.
        </p>
        <ul>
          {duplicateSourceIdsInSystem.map((sourceId: any) => (
            <li>{sourceId}</li>
          ))}
        </ul>
      </DuplicateSection>
    );
  }

  let dupeInFileLabel;
  if (duplicateSourceIdsInFile.length > 0) {
    dupeInFileLabel = (
      <DuplicateSection>
        <p>
          {duplicateSourceIdsInFile.length} Duplicates found in current file.
        </p>
        <ul>
          {duplicateSourceIdsInFile.map((sourceId: any) => (
            <li>{sourceId}</li>
          ))}
        </ul>
      </DuplicateSection>
    );
  }

  const optionLabel = (
    <div>
      {dupeInSystemLabel} {dupeInFileLabel} Choose an option:
    </div>
  );

  return (
    <Container>
      <FileUpload
        name="uploadKey"
        onFileUpload={onFileUpload}
        organisationId={organisationId}
        workspaceId={workspaceId}
        error={errors.uploadKey}
      />
      {showOptions && (
        <OptionsContainer>
          {optionLabel}
          <RadioGroup>
            <Radio
              name="uploadMode"
              required={true}
              form={form}
              options={[
                {
                  label: "Keep Original",
                  value: "keep_original",
                },
                {
                  label: "Override with New",
                  value: "override_with_new",
                },
              ]}
            />
          </RadioGroup>
        </OptionsContainer>
      )}
    </Container>
  );
};

export default ImportVerbatimsForm;
