import React, { useState } from "react";
import { Modal2 } from "sbui";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import ImportVerbatimsForm from "./ImportVerbatimsForm";
import { gql, useMutation } from "@apollo/client";
import { GET_VERBATIMS, VERBATIMS_PAGE_SIZE } from ".";

type Inputs = {
  uploadKey: string;
  uploadMode: string;
};

const CREATE_VERBATIMS = gql`
  mutation createVerbatims($input: CreateVerbatimsInput!) {
    createVerbatims(input: $input) {
      successCount
      failureCount
      failureIds
    }
  }
`;

const VALIDATE_VERBATIMS = gql`
  mutation validateVerbatims($input: CreateVerbatimsInput!) {
    validateVerbatims(input: $input) {
      duplicateSourceIdsInSystem
      duplicateSourceIdsInFile
    }
  }
`;

const ImportVerbatimsModal = ({
  isOpen,
  organisationId,
  workspaceId,
  onRequestClose,
}: any) => {
  const [showOptions, setShowOptions] = useState(false);
  const [duplicatesInSystem, setDuplicatesInSystem] = useState<any>([]);
  const [duplicatesInFile, setDuplicatesInFile] = useState<any>([]);
  const [failures, setFailures] = useState<any>([]);
  const { handleSubmit, ...form } = useForm<Inputs>({
    defaultValues: {
      uploadKey: "",
      uploadMode: "",
    },
  });
  const uploadKey = form.watch("uploadKey");
  const uploadMode = form.watch("uploadMode");

  const [validateVerbatims, { loading: validateLoading }] = useMutation(
    VALIDATE_VERBATIMS,
    {
      onError: (err) => {
        console.log("err", err);
      },
      onCompleted: async ({ validateVerbatims }) => {
        if (
          validateVerbatims.duplicateSourceIdsInFile.length === 0 &&
          validateVerbatims.duplicateSourceIdsInSystem.length === 0
        ) {
          await createVerbatims({
            variables: {
              input: {
                uploadKey: uploadKey,
                uploadMode: uploadMode,
                workspaceId,
                organisationId,
              },
            },
          });
        } else {
          setDuplicatesInSystem(validateVerbatims.duplicateSourceIdsInSystem);
          setDuplicatesInFile(validateVerbatims.duplicateSourceIdsInFile);
          setShowOptions(true);
        }
      },
    }
  );

  const [createVerbatims, { loading }] = useMutation(CREATE_VERBATIMS, {
    onError: (err) => {
      console.log("err", err);
    },
    onCompleted: ({ createVerbatims }: any) => {
      if (createVerbatims.failureCount > 0) {
        setFailures(createVerbatims.failureIds);
      } else {
        onRequestClose();
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_VERBATIMS,
        variables: {
          input: {
            workspaceId: workspaceId,
            limit: VERBATIMS_PAGE_SIZE,
            offset: 0,
          },
        },
      },
    ],
  });

  const onSubmit = async (data: Inputs) => {
    try {
      if (showOptions) {
        await createVerbatims({
          variables: {
            input: {
              uploadKey: uploadKey,
              uploadMode: uploadMode,
              workspaceId,
              organisationId,
            },
          },
        });
      } else {
        await validateVerbatims({
          variables: {
            input: {
              uploadKey: data.uploadKey,
              workspaceId,
              organisationId,
            },
          },
        });
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  return (
    <Modal2
      titleText="import-verbatims.heading"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={handleSubmit(onSubmit)}
      submitLabel="import.label"
      loading={loading || validateLoading}
      disableSubmit={failures.length > 0}
    >
      <ImportVerbatimsForm
        organisationId={organisationId}
        workspaceId={workspaceId}
        onSubmit={onRequestClose}
        form={form}
        showOptions={showOptions}
        duplicateSourceIdsInSystem={duplicatesInSystem}
        duplicateSourceIdsInFile={duplicatesInFile}
      />
      {}
      {failures.length > 0 && (
        <div>
          {failures.length} records failed to load.
          <ul>
            {failures.map((failure: any) => {
              return <li>{failure}</li>;
            })}
          </ul>
        </div>
      )}
    </Modal2>
  );
};

export default withRouter(ImportVerbatimsModal);
