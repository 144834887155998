import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "./TextField";
import { DEFAULT_DATE_SELECTOR_FORMAT } from "../../utils/date";

const DateSelect = ({
  label,
  value,
  error,
  touched,
  onChange,
  ...props
}: any) => {
  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      autoComplete="off"
      customInput={<TextField label={label} error={error} touched={touched} />}
      dateFormat={DEFAULT_DATE_SELECTOR_FORMAT}
      {...props}
    />
  );
};
export default DateSelect;
