import React from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import styled from "styled-components";

type RadioOption = {
  label: string;
  value: any;
};

type RadioProps = {
  label?: string;
  name: string;
  form: any;
  required?: boolean;
  options: RadioOption[];
} & JSX.IntrinsicElements["input"];

const StyledRadio = styled.input.attrs({
  type: "radio",
})``;

const FieldLabel = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.medium};
  margin-left: ${(props) => props.theme.spacing.xsmall};
`;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const RadioOptionContainer = styled.div`
  margin-left: 10px;
`;

const ErrorLabel = styled.div`
  color: ${(props) => props.theme.font.colors.error};
`;

const Radio = ({ label, options, name, form, required }: RadioProps) => {
  const { formatMessage } = useIntl();
  let errorMessage;

  const {
    control,
    formState: { errors },
  } = form;

  if (errors && errors[name] && errors[name].type === "required") {
    errorMessage = formatMessage({ id: "generic.error.required" });
  }

  return (
    <RadioContainer>
      <FieldLabel>{label && formatMessage({ id: label })}</FieldLabel>
      <RadioOptionsContainer>
        {options.map(({ label: optionLabel, value }: RadioOption) => (
          <RadioOptionContainer>
            <FieldLabel>{optionLabel}</FieldLabel>
            <Controller
              name={name}
              control={control}
              rules={{ required: required || false }}
              render={({ field }) => {
                return <StyledRadio {...field} name={name} value={value} />;
              }}
            />
          </RadioOptionContainer>
        ))}
      </RadioOptionsContainer>
      {errorMessage && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </RadioContainer>
  );
};

export default Radio;
