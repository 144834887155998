import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { CardButton, Card, CardActions } from "sbui";
import Loading from "sbui/components/loading";
import { GET_ORGANISATION } from "../Organisation";
import DeleteVerbatimModal from "./DeleteVerbatimModal";
import ExportVerbatimsModal from "./ExportVerbatimsModal";
import ImportVerbatimsModal from "./ImportVerbatimsModal";
import VerbatimsTable from "./VerbatimsTable";
import { PaginationContainer } from "components/PaginationContainer";
import { fetchMoreHelper } from "utils/pagination";
import { useBreadcrumbsDispatch } from "components/Breadcrumbs";
import ShowVerbatimModal from "./ShowVerbatimModal";

export const GET_WORKSPACE = gql`
  query workspace($workspaceId: Float!) {
    workspace(workspaceId: $workspaceId) {
      id
      name
    }
  }
`;

export const GET_VERBATIMS = gql`
  query verbatims($input: GetVerbatimsInput!) {
    verbatims(input: $input) {
      items {
        id
        sourceId
        verbatim
        status
        keywords
        targetClassValue
        targetClassName
        targetClassCorrect
        resolution
        tags {
          tagId
          tag {
            id
            tag
          }
          text
        }
        rootCause {
          id
          value
        }
        enquiry {
          id
          value
        }
        subEnquiry {
          id
          value
        }
      }
      total
      hasMore
    }
  }
`;

export const VERBATIMS_PAGE_SIZE = 100;

const Workspace = ({ match, history }: any) => {
  const [importVerbatimsModal, setImportVerbatimsModal] = useState(false);
  const [exportVerbatimsModal, setExportVerbatimsModal] = useState(false);
  const [deleteVerbatimModal, setDeleteVerbatimModal] = useState(false);
  const [selectedVerbatim, setSelectedVerbatim] = useState(null);
  const [showVerbatimModal, setShowVerbatimModal] = useState(false);

  const { loading: orgLoading, data: orgData } = useQuery(GET_ORGANISATION, {
    variables: {
      organisationId: parseInt(match.params.organisationId),
    },
  });

  const { loading: workspaceLoading, data: workspaceData } = useQuery(
    GET_WORKSPACE,
    {
      variables: {
        workspaceId: parseInt(match.params.id),
      },
    }
  );

  const { loading, data, fetchMore } = useQuery(GET_VERBATIMS, {
    variables: {
      input: {
        workspaceId: parseInt(match.params.id),
        limit: VERBATIMS_PAGE_SIZE,
        offset: 0,
      },
    },
  });

  const dispatch = useBreadcrumbsDispatch();
  useEffect(() => {
    if (orgData && workspaceData) {
      dispatch({
        type: "setBreadcrumbs",
        value: [
          {
            label: "organisations.heading",
            to: "/organisations",
          },
          {
            label: orgData.organisation.name,
            to: `/organisations/${orgData.organisation.id}`,
          },
          {
            label: workspaceData.workspace.name,
          },
        ],
      });
    }
  }, [dispatch, workspaceData, orgData]);

  if (loading || orgLoading || workspaceLoading) {
    return <Loading />;
  }

  return (
    <>
      <Card
        heading="verbatims.heading"
        actions={
          <CardActions>
            <CardButton
              label="import.label"
              onClick={() => {
                setImportVerbatimsModal(true);
              }}
            />
            <CardButton
              primary
              label="classify.label"
              onClick={() => {
                history.push(
                  `/organisations/${orgData.organisation.id}/workspaces/${workspaceData.workspace.id}/classify`
                );
              }}
            />
            <CardButton
              label="export.label"
              onClick={() => {
                setExportVerbatimsModal(true);
              }}
            />
          </CardActions>
        }
      >
        {importVerbatimsModal && (
          <ImportVerbatimsModal
            isOpen={importVerbatimsModal}
            organisationId={orgData.organisation.id}
            workspaceId={workspaceData.workspace.id}
            onRequestClose={() => {
              setImportVerbatimsModal(false);
            }}
          />
        )}
        {exportVerbatimsModal && (
          <ExportVerbatimsModal
            isOpen={exportVerbatimsModal}
            organisationId={orgData.organisation.id}
            workspaceId={workspaceData.workspace.id}
            onRequestClose={() => {
              setExportVerbatimsModal(false);
            }}
          />
        )}
        {deleteVerbatimModal && (
          <DeleteVerbatimModal
            isOpen={deleteVerbatimModal}
            verbatim={selectedVerbatim}
            workspaceId={workspaceData.workspace.id}
            onRequestClose={() => {
              setDeleteVerbatimModal(false);
            }}
          />
        )}
        {showVerbatimModal && (
          <ShowVerbatimModal
            isOpen={showVerbatimModal}
            verbatim={selectedVerbatim}
            onRequestClose={() => {
              setShowVerbatimModal(false);
            }}
          />
        )}

        <PaginationContainer
          hasMore={data?.verbatims?.hasMore}
          onLoadMore={(done: any) => {
            fetchMoreHelper(fetchMore, "verbatims", done, {
              input: {
                workspaceId: parseInt(match.params.id),
                offset: data.verbatims.items.length,
                limit: VERBATIMS_PAGE_SIZE,
              },
            });
          }}
        >
          <VerbatimsTable
            data={data?.verbatims?.items || []}
            deleteHandler={(verbatim: any) => {
              setSelectedVerbatim(verbatim);
              setDeleteVerbatimModal(true);
            }}
            organisationId={orgData.organisation.id}
            workspaceId={workspaceData.workspace.id}
          />
        </PaginationContainer>
      </Card>
    </>
  );
};

export default withRouter(Workspace);
