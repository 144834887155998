import React from "react";
import { useIntl } from "react-intl";
import { Modal2 } from "sbui";
import styled from "styled-components";
import { VerbatimTag } from "types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldRow = styled.div`
  margin-bottom: 5px;
  display: flex;
`;
const FieldLabel = styled.div`
  width: 100px;
`;
const FieldValue = styled.div``;
const TagContainer = styled.ul`
  max-width: 40vw;
  list-style: none;
  li {
    margin-top: 5px;
    margin-bottom 5px;
  }
`;

const VerbatimContainer = styled.div`
  flex: 0.6;
  overflow-y: scroll;
  max-height: 40vh;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const ShowVerbatimModal = ({ isOpen, verbatim, onRequestClose }: any) => {
  const intl = useIntl();

  const verbatimStatus = intl.formatMessage({
    id: `verbatim.status.${verbatim.status}`,
  });

  const tagViews = (verbatim.tags || []).map((tag: VerbatimTag) => {
    return (
      <li>
        <strong>{tag.tag?.tag}</strong> - {tag.text}
      </li>
    );
  });

  const keywordViews = (verbatim.keywords || []).join(", ");

  return (
    <Modal2
      titleText={"Verbatim"}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={onRequestClose}
      submitLabel={"Close"}
    >
      <Container>
        <FieldRow>
          <FieldLabel>Source ID:</FieldLabel>
          <FieldValue>{verbatim.sourceId}</FieldValue>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Status:</FieldLabel>
          <FieldValue>{verbatimStatus}</FieldValue>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Root Cause:</FieldLabel>
          <FieldValue>{verbatim.rootCause?.value || "-"}</FieldValue>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Enquiry:</FieldLabel>
          <FieldValue>{verbatim.enquiry?.value || "-"}</FieldValue>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Subenquiry:</FieldLabel>
          <FieldValue>{verbatim.subEnquiry?.value || "-"}</FieldValue>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Tags:</FieldLabel>
          <FieldValue>
            <TagContainer>{tagViews.length > 0 ? tagViews : "-"}</TagContainer>
          </FieldValue>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Keywords:</FieldLabel>
          <FieldValue>
            {keywordViews.length > 0 ? keywordViews : "-"}
          </FieldValue>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Verbatim:</FieldLabel>
          <VerbatimContainer>{verbatim.verbatim}</VerbatimContainer>
        </FieldRow>
      </Container>
    </Modal2>
  );
};

export default ShowVerbatimModal;
