import { gql, useMutation } from "@apollo/client";
import { SvgIconProps, SvgIcon } from "@material-ui/core";
import React, { useState } from "react";
import { Checkbox, TextField } from "sbui";
import styled from "styled-components";
import { Enquiry, RootCause, SubEnquiry } from "types";

const Container = styled.div`
  margin: 10px;
`;

const ItemContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ChildrenContainer = styled.div`
  margin-left: 25px;
  margin-top: 5px;
`;

const Label = styled.span`
  display: flex;
  align-items: center;
`;

const CheckboxContainer = styled.span`
  margin-left: 10px;
`;

const EmptyIcon = styled.span`
  margin-left: 14px;
`;

const ItemRow = styled.div`
  display: flex;
`;

const VerbatimCount = styled.span`
  margin-left: 5px;
  font-size: 12px;
  color: #aaa;
`;

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

const UPDATE_REFERENCE_DATA = gql`
  mutation updateReferenceData($input: UpdateReferenceDataInput!) {
    updateReferenceData(input: $input)
  }
`;

const EditableLabel = ({ id, type, name, defaultValue, saveChanges }: any) => {
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState<any>();
  const [value, setValue] = useState(defaultValue);

  const handleSaveChanges = async (value: any) => {
    if (value === "" || value === defaultValue) {
      setEditing(false);
      return;
    }

    setValue(value.trim());

    const saveError = await saveChanges({
      id,
      type,
      value,
    });

    if (saveError) {
      setError(saveError);
    } else {
      setError(null);
      setEditing(false);
    }
  };

  return (
    <>
      {!editing && <span onClick={() => setEditing(true)}>{value}</span>}
      {editing && (
        <TextField
          marginOverride="5px"
          onBlur={(e: any) => handleSaveChanges(e.target.value)}
          onKeyUp={(e: any) => {
            if (e.keyCode === 13) {
              handleSaveChanges(e.target.value);
            }
          }}
          onChange={(e: any) => {
            setValue(e.target.value);
          }}
          error={error}
          touched={!!error}
          name={name}
          value={value}
          autoFocus
        />
      )}
    </>
  );
};

const RootCauseItem = ({
  onChange,
  referenceDataVerbatimCount,
  rootCause,
  saveChanges,
  rootCauseItemState,
  setRootCauseItemState,
  enquiryItemState,
  setEnquiryItemState,
}: any) => {
  const [checked, setChecked] = useState();
  const key = `rootCause:${rootCause.id}`;
  const count = referenceDataVerbatimCount.find(
    (r: any) =>
      r.referenceDataType === "rootCause" && r.referenceDataId === rootCause.id
  );

  const icon = rootCauseItemState?.[key]?.open ? (
    <MinusSquare />
  ) : (
    <PlusSquare />
  );

  const enquiryItems = [...rootCause.enquiries]
    .sort((a: Enquiry, b: Enquiry) => {
      return a.value.localeCompare(b.value);
    })
    .map((enquiry: Enquiry) => {
      return (
        <EnquiryItem
          enquiry={enquiry}
          onChange={onChange}
          saveChanges={saveChanges}
          referenceDataVerbatimCount={referenceDataVerbatimCount}
          enquiryItemState={enquiryItemState}
          setEnquiryItemState={setEnquiryItemState}
        />
      );
    });

  const collapseable = rootCause.enquiries.length > 0;

  const handleOpen = () => {
    if (!rootCauseItemState?.[key]) {
      return setRootCauseItemState({
        ...rootCauseItemState,
        [key]: {
          key,
          checked: rootCauseItemState?.[key]?.checked || false,
          open: true,
        },
      });
    }

    setRootCauseItemState({
      ...rootCauseItemState,
      [key]: {
        key,
        checked: rootCauseItemState?.[key]?.checked || false,
        open: !rootCauseItemState[key].open,
      },
    });
  };

  return (
    <ItemContainer>
      <ItemRow>
        {collapseable ? (
          <span onClick={() => handleOpen()}>{icon}</span>
        ) : (
          <EmptyIcon />
        )}
        <CheckboxContainer>
          <Checkbox
            name={key}
            onChange={(e: any) => {
              setChecked(e.target.checked);
              onChange(e);
            }}
            checked={checked}
          />
        </CheckboxContainer>
        <Label>
          <EditableLabel
            id={rootCause.id}
            saveChanges={saveChanges}
            type="rootCause"
            defaultValue={rootCause.value}
            name={`rootCause[${rootCause.id}]`}
          />
          <VerbatimCount>{count && `${count.count}`}</VerbatimCount>
        </Label>
      </ItemRow>
      <ChildrenContainer>
        {rootCauseItemState?.[key]?.open && enquiryItems}
      </ChildrenContainer>
    </ItemContainer>
  );
};

const EnquiryItem = ({
  enquiry,
  enquiryItemState,
  onChange,
  referenceDataVerbatimCount,
  saveChanges,
  setEnquiryItemState,
}: any) => {
  const [checked, setChecked] = useState();
  const key = `enquiry:${enquiry.id}`;
  const icon = enquiryItemState?.[key]?.open ? <MinusSquare /> : <PlusSquare />;
  const count = referenceDataVerbatimCount.find(
    (r: any) =>
      r.referenceDataType === "enquiry" && r.referenceDataId === enquiry.id
  );

  const subEnquiryItems = [...enquiry.subEnquiries]
    .sort((a: Enquiry, b: Enquiry) => {
      return a.value.localeCompare(b.value);
    })
    .map((subEnquiry: SubEnquiry) => {
      return (
        <SubEnquiryItem
          subEnquiry={subEnquiry}
          onChange={onChange}
          saveChanges={saveChanges}
          referenceDataVerbatimCount={referenceDataVerbatimCount}
        />
      );
    });

  const collapseable = enquiry.subEnquiries.length > 0;

  const handleOpen = () => {
    if (!enquiryItemState?.[key]) {
      return setEnquiryItemState({
        ...enquiryItemState,
        [key]: {
          key,
          checked: enquiryItemState?.[key]?.checked || false,
          open: true,
        },
      });
    }

    setEnquiryItemState({
      ...enquiryItemState,
      [key]: {
        key,
        checked: enquiryItemState?.[key]?.checked || false,
        open: !enquiryItemState[key].open,
      },
    });
  };

  return (
    <ItemContainer>
      <ItemRow>
        {collapseable ? (
          <span onClick={() => handleOpen()}>{icon}</span>
        ) : (
          <EmptyIcon />
        )}
        <CheckboxContainer>
          <Checkbox
            name={key}
            onChange={(e: any) => {
              setChecked(e.target.checked);
              onChange(e);
            }}
            checked={checked}
          />
        </CheckboxContainer>
        <Label>
          <EditableLabel
            id={enquiry.id}
            saveChanges={saveChanges}
            type="enquiry"
            defaultValue={enquiry.value}
            name={`enquiry[${enquiry.id}]`}
          />
          <VerbatimCount>{count && `${count.count}`}</VerbatimCount>
        </Label>
      </ItemRow>
      <ChildrenContainer>
        {enquiryItemState?.[key]?.open && subEnquiryItems}
      </ChildrenContainer>
    </ItemContainer>
  );
};

const SubEnquiryItem = ({
  subEnquiry,
  onChange,
  referenceDataVerbatimCount,
  saveChanges,
}: any) => {
  const [checked, setChecked] = useState();
  const key = `subEnquiry:${subEnquiry.id}`;
  const count = referenceDataVerbatimCount.find(
    (r: any) =>
      r.referenceDataType === "subEnquiry" &&
      r.referenceDataId === subEnquiry.id
  );

  return (
    <ItemContainer>
      <ItemRow>
        <EmptyIcon />
        <CheckboxContainer>
          <Checkbox
            name={key}
            onChange={(e: any) => {
              setChecked(e.target.checked);
              onChange(e);
            }}
            checked={checked}
          />
        </CheckboxContainer>
        <Label>
          <EditableLabel
            id={subEnquiry.id}
            saveChanges={saveChanges}
            type="subEnquiry"
            defaultValue={subEnquiry.value}
            name={`subEnquiry[${subEnquiry.id}]`}
          />
          <VerbatimCount>{count && `${count.count}`}</VerbatimCount>
        </Label>
      </ItemRow>
    </ItemContainer>
  );
};

const RootCausesTree = ({
  data,
  onChangeHandler,
  referenceDataVerbatimCount,
  enquiryItemState,
  rootCauseItemState,
  setEnquiryItemState,
  setRootCauseItemState,
}: any) => {
  const [updateReferenceData] = useMutation(UPDATE_REFERENCE_DATA);

  const saveChanges = async ({ id, type, value }: any) => {
    let error;
    try {
      await updateReferenceData({
        variables: {
          input: {
            id,
            type,
            value,
          },
        },
      });
    } catch (e: any) {
      error = e.message;
    }

    return error;
  };

  const rootCauseItems = data.map((rootCause: RootCause) => {
    return (
      <RootCauseItem
        key={rootCause.id}
        rootCause={rootCause}
        onChange={onChangeHandler}
        saveChanges={saveChanges}
        referenceDataVerbatimCount={referenceDataVerbatimCount}
        rootCauseItemState={rootCauseItemState}
        setRootCauseItemState={setRootCauseItemState}
        enquiryItemState={enquiryItemState}
        setEnquiryItemState={setEnquiryItemState}
      />
    );
  });

  return <Container>{rootCauseItems}</Container>;
};

export default RootCausesTree;
