import React from "react";

import gql from "graphql-tag";
import { Modal2 } from "sbui";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { GET_ROOT_CAUSES } from "../Classify";
import styled from "styled-components";
import { GET_REFERENCE_DATA_VERBATIM_COUNT } from ".";
import MoveEnquiriesForm from "./MoveEnquiriesForm";

const MOVE_ENQUIRIES = gql`
  mutation moveEnquiries($input: MoveEnquiriesInput!) {
    moveEnquiries(input: $input)
  }
`;

type Inputs = {
  rootCause: any;
};

const HelperText = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;
`;

const WarningText = styled.div`
  ul {
    list-style: none;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const MoveEnquiriesModal = ({
  isOpen,
  referenceData,
  rootCauses,
  organisationId,
  onRequestClose,
}: any) => {
  let valueMap: any = {};

  rootCauses.forEach((rootCause: any) => {
    valueMap[`rootCause:${rootCause.id}`] = rootCause.value;
    rootCause.enquiries.forEach((enquiry: any) => {
      valueMap[`enquiry:${enquiry.id}`] = enquiry.value;
      enquiry.subEnquiries.forEach((subEnquiry: any) => {
        valueMap[`subEnquiry:${subEnquiry.id}`] = subEnquiry.value;
      });
    });
  });

  const { handleSubmit, ...form } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    moveEnquiries({
      variables: {
        input: {
          toRootCauseId: data.rootCause.value.id,
          enquiryIds: referenceData.map((r: any) => parseInt(r.id)),
        },
      },
    });
  };

  const [moveEnquiries, { loading }] = useMutation(MOVE_ENQUIRIES, {
    onCompleted: (res) => {
      onRequestClose(true);
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ROOT_CAUSES,
        variables: {
          input: {
            organisationId,
          },
        },
      },
      {
        query: GET_REFERENCE_DATA_VERBATIM_COUNT,
        variables: {
          organisationId: organisationId,
        },
      },
    ],
  });

  let matchedEnquiries: any = [];
  const rootCause = form.watch("rootCause");
  if (rootCause?.value) {
    const selectedRootCause = rootCause?.value;

    referenceData.forEach((refData: any) => {
      const enquiryValue = valueMap[`enquiry:${refData.id}`];
      if (
        selectedRootCause.enquiries.find((e: any) => e.value === enquiryValue)
      ) {
        matchedEnquiries.push(enquiryValue);
      }
    });
  }

  return (
    <Modal2
      titleText="referenceData.merge.label"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
    >
      <HelperText>
        Select a root cause to move the selected enquiries into:{" "}
      </HelperText>
      <MoveEnquiriesForm
        onSubmit={onRequestClose}
        form={form}
        rootCauses={rootCauses}
        referenceData={referenceData}
      />
      {matchedEnquiries.length > 0 && (
        <WarningText>
          <p>
            The following enquiries are duplicates in the root cause you are
            moving them to.
          </p>
          <p>They will be merged if you proceed.</p>
          <ul>
            {matchedEnquiries.map((v: any) => (
              <li>{v}</li>
            ))}
          </ul>
        </WarningText>
      )}
    </Modal2>
  );
};

export default withRouter(MoveEnquiriesModal);
