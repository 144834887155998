import React from "react";
import { RegularText } from "sbui";
import styled from "styled-components";

interface ErrorProps {
  error?: string | undefined | null;
}

const ErrorLabel = styled.div`
  color: ${(props: any) => props.theme.font.colors.error};
`;

const Error = ({ error }: ErrorProps) => (
  <ErrorLabel data-test="error">
    {<RegularText label={error || ""} />}
  </ErrorLabel>
);

export default Error;
