import React, { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Card } from "sbui";
import Loading from "sbui/components/loading";
import TagsTable from "./TagsTable";
import { useBreadcrumbsDispatch } from "components/Breadcrumbs";

export const TAGS = gql`
  query tags($input: TagsInput) {
    tags(input: $input) {
      id
      tag
      createdAt
    }
  }
`;

const Tags = () => {
  const { loading, data } = useQuery(TAGS);

  const dispatch = useBreadcrumbsDispatch();
  useEffect(() => {
    dispatch({
      type: "setBreadcrumbs",
      value: [
        {
          label: "tags.heading",
        },
      ],
    });
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Card heading="tags.heading">
      <TagsTable data={data?.tags || []} />
    </Card>
  );
};

export default Tags;
