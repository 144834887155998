import React from "react";

export type BreadcrumbItem = {
  to?: string;
  label: string;
  values?: any;
};

type Action = { type: "setBreadcrumbs"; value: BreadcrumbItem[] };
type Dispatch = (action: Action) => void;
type State = { breadcrumbs: BreadcrumbItem[] };
type BreadcrumbsProviderProps = { children: React.ReactNode };

const BreadcrumbsStateContext = React.createContext<State | undefined>(
  undefined
);
const BreadcrumbsDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function BreadcrumbsReducer(state: State, action: Action) {
  switch (action.type) {
    case "setBreadcrumbs":
      return { breadcrumbs: action.value };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const BreadcrumbsProvider = ({ children }: BreadcrumbsProviderProps) => {
  const [state, dispatch] = React.useReducer(BreadcrumbsReducer, {
    breadcrumbs: [],
  });
  return (
    <BreadcrumbsStateContext.Provider value={state}>
      <BreadcrumbsDispatchContext.Provider value={dispatch}>
        {children}
      </BreadcrumbsDispatchContext.Provider>
    </BreadcrumbsStateContext.Provider>
  );
};

export const useBreadcrumbsState = () => {
  const context = React.useContext(BreadcrumbsStateContext);
  if (context === undefined) {
    throw new Error(
      "useBreadcrumbsState must be used within a BreadcrumbsProvider"
    );
  }
  return context;
};

export const useBreadcrumbsDispatch = () => {
  const context = React.useContext(BreadcrumbsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useBreadcrumbsDispatch must be used within a BreadcrumbsProvider"
    );
  }
  return context;
};
