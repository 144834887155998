import { useQuery } from "@apollo/client";
import { useBreadcrumbsDispatch } from "components/Breadcrumbs";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Card, CardActions, CardButton } from "sbui";
import Loading from "sbui/components/loading";
import { GET_TAGS } from "../Classify/NewTagModal";
import ExportTagsModal from "../Tags/ExportTagsModal";
import TagsTable from "../Tags/TagsTable";
import DeleteWorkspaceModal from "./DeleteWorkspaceModal";
import NewWorkspaceModal from "./NewWorkspaceModal";
import WorkspacesTable from "./WorkspacesTable";

export const GET_ORGANISATION = gql`
  query organisation($organisationId: Float!) {
    organisation(organisationId: $organisationId) {
      id
      name
      createdAt
    }
  }
`;

export const GET_WORKSPACES = gql`
  query workspaces($organisationId: Float!) {
    workspaces(organisationId: $organisationId) {
      id
      name
      type
      verbatimCount
      organisationId
      createdAt
      owner {
        id
        firstName
        email
      }
    }
  }
`;

const Organisation = ({ match, history }: any) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(null);
  const [newWorkspaceModalOpen, setNewWorkspaceModalOpen] = useState(false);
  const [
    uploadAndAllocateWorkspaceModalOpen,
    setUploadAndAllocateWorkspaceModalOpen,
  ] = useState(false);
  const [deleteWorkspaceModalOpen, setDeleteWorkspaceModalOpen] =
    useState(false);
  const [exportTagsModalOpen, setExportTagsModalOpen] = useState(false);

  const { loading, data } = useQuery(GET_ORGANISATION, {
    variables: {
      organisationId: parseInt(match.params.id),
    },
  });

  const { loading: loadingWorkspaces, data: dataWorkspaces } = useQuery(
    GET_WORKSPACES,
    {
      variables: {
        organisationId: parseInt(match.params.id),
      },
    }
  );

  const { loading: loadingTags, data: tagsData } = useQuery(GET_TAGS, {
    variables: {
      input: {
        organisationId: parseInt(match.params.id),
      },
    },
  });

  const dispatch = useBreadcrumbsDispatch();
  useEffect(() => {
    if (data) {
      dispatch({
        type: "setBreadcrumbs",
        value: [
          {
            label: "organisations.heading",
            to: "/organisations",
          },
          {
            label: data.organisation.name,
          },
        ],
      });
    }
  }, [dispatch, data]);

  if (loading || loadingTags || loadingWorkspaces) {
    return <Loading />;
  }
  return (
    <>
      <Card
        heading="workspaces.heading"
        actions={
          <CardActions>
            <CardButton
              label="rootCauses.manage.label"
              onClick={() => {
                history.push(
                  `/organisations/${data.organisation.id}/root-causes`
                );
              }}
            />
            <CardButton
              primary
              label="workspaces.allocate.label"
              onClick={() => {
                history.push(
                  `/organisations/${data.organisation.id}/allocate-workspaces`
                );
              }}
            />
            <CardButton
              primary
              label="workspaces.add.label"
              onClick={() => {
                setNewWorkspaceModalOpen(true);
              }}
            />
          </CardActions>
        }
      >
        {newWorkspaceModalOpen && (
          <NewWorkspaceModal
            isOpen={newWorkspaceModalOpen}
            organisationId={data.organisation.id}
            onRequestClose={() => {
              setNewWorkspaceModalOpen(false);
            }}
          />
        )}

        {deleteWorkspaceModalOpen && (
          <DeleteWorkspaceModal
            isOpen={deleteWorkspaceModalOpen}
            workspace={selectedWorkspace}
            onRequestClose={() => {
              setDeleteWorkspaceModalOpen(false);
            }}
          />
        )}

        {exportTagsModalOpen && (
          <ExportTagsModal
            isOpen={exportTagsModalOpen}
            organisationId={data.organisation.id}
            onRequestClose={() => {
              setExportTagsModalOpen(false);
            }}
          />
        )}

        <WorkspacesTable
          data={dataWorkspaces?.workspaces || []}
          deleteHandler={(workspace: any) => {
            setSelectedWorkspace(workspace);
            setDeleteWorkspaceModalOpen(true);
          }}
        />
      </Card>
      <Card
        heading="tags.heading"
        actions={
          <CardActions>
            <CardButton
              label="export-tags.heading"
              onClick={() => setExportTagsModalOpen(true)}
            />
          </CardActions>
        }
      >
        <TagsTable data={tagsData?.tags || []} />
      </Card>
    </>
  );
};

export default withRouter(Organisation);
