import React, { useMemo } from "react";
import { Table, SingleColumnTable, Button } from "sbui";
import { withRouter } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useIntl } from "react-intl";
import styled from "styled-components";

const Actions = styled.div`
  display: flex;
  button {
    margin-right: 10px;
  }
`;

const getColumns = ({
  intl,
  editHandler,
  deleteHandler,
  currentUser,
  kickSessionsHandler,
}: any) => [
  {
    Header: "name.label",
    accessor: "firstName",
    width: 20,
    Cell: ({ row }: any) => {
      return `${row.original.firstName} ${row.original.lastName}`;
    },
  },
  {
    Header: "email.label",
    accessor: "email",
    width: 40,
  },
  {
    Header: "role.label",
    accessor: "role",
    width: 10,
  },
  {
    Header: "actions.label",
    accessor: "actions",
    width: 30,
    Cell: ({ row }: any) => {
      return (
        <Actions>
          {currentUser.id !== row.original.id && (
            <Button
              label="delete.label"
              onClick={() => {
                deleteHandler(row.original);
              }}
            />
          )}
          {currentUser.id !== row.original.id && (
            <Button
              label="kickSessionsButton.label"
              onClick={() => {
                kickSessionsHandler(row.original);
              }}
            />
          )}
        </Actions>
      );
    },
  },
];

const UsersTable = ({
  data,
  currentUser,
  editHandler,
  deleteHandler,
  kickSessionsHandler,
}: any) => {
  const intl = useIntl();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const tableColumns = useMemo(
    () =>
      getColumns({
        intl,
        editHandler,
        currentUser,
        deleteHandler,
        kickSessionsHandler,
      }),
    [intl, editHandler, deleteHandler, currentUser, kickSessionsHandler]
  );

  const TableComponent = isMobile ? SingleColumnTable : Table;

  return <TableComponent columns={tableColumns} data={data} />;
};

export default withRouter(UsersTable);
