import React, { useMemo } from "react";
import ReactJson from 'react-json-view'
import { Table, SingleColumnTable } from "sbui";
import { withRouter } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useIntl } from "react-intl";
import FromNowCell from "components/FromNowCell";

const getColumns = ({ intl }: any) => [
  {
    Header: "action.label",
    accessor: "action",
    width: 20,
  },
  {
    Header: "actionedAt.label",
    accessor: "createdAt",
    width: 20,
    Cell: ({ row }: any) => {
      return <FromNowCell date={row.original.createdAt} />;
    },
  },
  {
    Header: "user.label",
    accessor: "userEmail",
    width: 50,
  },
  {
    Header: "ipAddress.label",
    accessor: "ipAddress",
    width: 40,
  },
  {
    Header: "context.label",
    accessor: "context",
    width: 40,
    Cell: ({row }: any) => {
      return <ReactJson src={row.original.context} />
    }
  },
];

const AccessLogsTable = ({ data }: any) => {
  const intl = useIntl();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const tableColumns = useMemo(
    () =>
      getColumns({
        intl,
      }),
    [intl]
  );

  const TableComponent = isMobile ? SingleColumnTable : Table;

  return <TableComponent columns={tableColumns} data={data} />;
};

export default withRouter(AccessLogsTable);
