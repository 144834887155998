import "react-toastify/dist/ReactToastify.css";
import { ToastContainer as BaseToastContainer } from "react-toastify";
import styled from "styled-components";
export { toast } from "react-toastify";

export const ToastContainer = styled(BaseToastContainer)`
  .Toastify__toast--success {
    background: ${props => props.theme.link.color};
  }
`;
