import React from "react";

import gql from "graphql-tag";
import { Modal2, RegularText } from "sbui";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { GET_ORGANISATIONS } from "./index";

export const DELETE_ORGANISATION = gql`
  mutation deleteOrganisation($input: DeleteOrganisationInput!) {
    deleteOrganisation(input: $input)
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextContainer = styled.div`
  margin-top: 10px;
  bottom-top: 10px;
`;

const DeleteOrganisationModal = ({
  isOpen,
  organisation,
  onRequestClose,
}: any) => {
  const [deleteOrganisation, { loading }] = useMutation(DELETE_ORGANISATION, {
    onCompleted: () => {
      onRequestClose();
    },
    refetchQueries: [
      {
        query: GET_ORGANISATIONS,
      },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <Modal2
      titleText="deleteOrganisation.label"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={() => {
        deleteOrganisation({
          variables: {
            input: {
              organisationId: organisation.id,
            },
          },
        });
      }}
      loading={loading}
    >
      <Container>
        {`Name: ${organisation.name}`}
        <TextContainer>
          <RegularText label="Are you sure you want to delete this organisation?" />
        </TextContainer>
      </Container>
    </Modal2>
  );
};

export default DeleteOrganisationModal;
