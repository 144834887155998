import React from "react";

import gql from "graphql-tag";
import { Modal2 } from "sbui";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import OrganisationForm from "./OrganisationForm";
import { GET_ORGANISATIONS } from "./index";

const CREATE_ORGANISATION = gql`
  mutation createOrganisation($input: CreateOrganisationInput!) {
    createOrganisation(input: $input) {
      id
    }
  }
`;

type Inputs = {
  name: string;
};

const NewOrganisationModal = ({ isOpen, onRequestClose }: any) => {
  const { handleSubmit, ...form } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    createOrganisation({
      variables: {
        input: {
          name: data.name,
        },
      },
    });
  };

  const [createOrganisation, { loading }] = useMutation(CREATE_ORGANISATION, {
    onError: (err) => {
      if (err.graphQLErrors && err.graphQLErrors.length >= 1) {
        form.setError("name", {
          type: "manual",
          message: err.graphQLErrors[0].message,
        });
      }
    },
    onCompleted: (res) => {
      onRequestClose();
    },
    refetchQueries: [
      {
        query: GET_ORGANISATIONS,
      },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <Modal2
      titleText="organisations.add.label"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
    >
      <OrganisationForm onSubmit={onRequestClose} form={form} />
    </Modal2>
  );
};

export default withRouter(NewOrganisationModal);
