import React from "react";
import { Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { IntlProvider } from "react-intl";
import { ApolloProvider } from "@apollo/client";
import en from "./assets/i18n/en.json";
import history from "./utils/history";
import Routes from "./routes";
import theme, { GlobalStyle } from "./sbui/theme";
import { client } from "./utils/client";
import { ToastContainer, toast } from "sbui";
import getUserLocale from "get-user-locale";
import { BreadcrumbsProvider } from "components/Breadcrumbs";

function Root({ currentLocale = getUserLocale() }) {
  return (
    <IntlProvider locale={currentLocale} messages={en}>
      <BreadcrumbsProvider>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <GlobalStyle />
            <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
            <Router history={history}>
              <Routes />
            </Router>
          </ApolloProvider>
        </ThemeProvider>
      </BreadcrumbsProvider>
    </IntlProvider>
  );
}

export default Root;
