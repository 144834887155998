import React, { useState, useEffect } from "react";
import UserRoutes from "routes/UserRoutes";
import styled, { css } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { IconButton } from "sbui";
import Sidebar from "./Sidebar";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import Loading from "sbui/components/loading";
import { useQuery } from "@apollo/client";
import AvatarMenu from "./AvatarMenu";
import { useGlobalStore } from "utils/state";
import { getAvailableRoutes } from "routes/UserRoutes/routes";
import { BreadcrumbsView } from "components/Breadcrumbs";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

const PageContainer = styled.div`
  /* background-color: ${(props) =>
    props.theme.page.container.backgroundColor}; */
  padding: ${(props) => props.theme.page.container.padding};
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
    padding: 0;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  color: ${(props) => props.theme.header.color};
  background-color: ${(props) => props.theme.header.backgroundColor};
  border-bottom: 2px solid ${(props) => props.theme.header.borderColor};
  padding: ${(props) => props.theme.spacing.large};
  display: flex;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
    flex-direction: column;
  }
`;

const HeaderLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
    margin-bottom: ${(props) => props.theme.spacing.xlarge};
  }
`;
const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div<{ hide: boolean }>`
  flex: 1;
  background-color: ${(props) => props.theme.page.content.backgroundColor};
  padding: ${(props) => props.theme.page.content.padding};
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
    padding: 0;
  }
  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}
`;

export const GET_ME = gql`
  query me {
    me {
      id
      firstName
      lastName
      email
      role
    }
  }
`;

const UserShell = ({ history }: any) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { setUser } = useGlobalStore();
  const { loading, data } = useQuery(GET_ME, {
    onCompleted: (res) => {
      setUser(res.me);

      setSidebarItems(
        getAvailableRoutes(data.me)
          .filter((route: any) => !route.hideFromSidebar)
          .map((route: any) => {
            return {
              title: `user.shell.sidebar.${route.name}`,
              path: route.path,
            };
          })
      );
    },
  });
  const [sidebarItems, setSidebarItems] = useState([]);

  useEffect(() => {
    if (!isMobile) {
      setSidebarOpen(false);
    }
  }, [isMobile]);

  if (loading) return <Loading />;

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const showLeftSidebar = !isMobile;
  const showOverlaySidebar = isMobile && sidebarOpen;

  const sidebar = (
    <Sidebar
      onMenuItemClick={() => {
        setSidebarOpen(false);
      }}
      isMobile={isMobile}
      menuItems={sidebarItems}
    />
  );

  const dropdownMenuItems = [
    {
      title: "user.shell.menu.logout",
      path: "/logout",
    },
  ];

  const onMenuSelect = (item: any) => {
    history.push(item.path);
  };

  return (
    <Container>
      <title>Title</title>
      {showLeftSidebar && sidebar}
      <InnerContainer>
        <Header>
          <HeaderLeft>
            {isMobile && (
              <IconButton
                name={sidebarOpen ? "menu_open" : "menu"}
                onClick={handleSidebarToggle}
              />
            )}
            <BreadcrumbsView />
          </HeaderLeft>
          <HeaderRight>
            <AvatarMenu
              user={data && data.me}
              items={dropdownMenuItems}
              onSelect={onMenuSelect}
            />
          </HeaderRight>
        </Header>
        {showOverlaySidebar && sidebar}
        <Content hide={showOverlaySidebar}>
          <PageContainer>
            <UserRoutes user={data?.me} />
          </PageContainer>
        </Content>
        {/* <Footer inverse={true} /> */}
      </InnerContainer>
    </Container>
  );
};

export default withRouter(UserShell);
