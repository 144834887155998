import { SelectField } from "components/FormFields/SelectField";
import React, { useState } from "react";
import { Button, Radio } from "sbui";
import { renderTag } from "sbui/utils/tags";
import styled from "styled-components";
import { Enquiry, RootCause, SubEnquiry } from "types";
import NewTagModal from "../Classify/NewTagModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 10px;
`;

const FieldGroup = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const RadioGroup = styled.div`
  input[type="radio"] {
    border: 1px red;
  }
`;
const StyledSelectField = styled(SelectField)`
  flex: 1;
  margin: 5px;
`;

const FormButton = styled(Button)`
  align-self: flex-end;
  font-size: 10px;
`;

const ClassificationForm = ({ form, rootCauses, verbatim }: any) => {
  const [showNewTagModal, setShowNewTagModal] = useState(false);
  const [highlightedText, setHighlightedText] = useState("");

  const selectedRootCauseOption = form.watch("rootCause");
  const selectedEnquiryOption = form.watch("enquiry");
  const currentTags = form.watch("tags");

  const labelSorter = (a: any, b: any) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const resolutionOptions = [
    {
      label: "",
      value: null,
    },
    {
      label: "Resolved",
      value: "Resolved",
    },
    {
      label: "Not Resolved",
      value: "Not Resolved",
    },
    {
      label: "Transferred",
      value: "Transferred",
    },
  ];

  const rootCauseOptions = rootCauses
    .map((rootCause: RootCause) => {
      return {
        label: rootCause.value,
        value: rootCause.id,
      };
    })
    .sort(labelSorter);

  const selectedRootCause = rootCauses.find(
    (rootCause: RootCause) => rootCause.id === selectedRootCauseOption?.value
  );

  let enquiries = (selectedRootCause?.enquiries || [])
    .map((enquiry: Enquiry) => {
      return {
        label: enquiry.value,
        value: enquiry.id,
      };
    })
    .sort(labelSorter);

  const selectedEnquiry = (selectedRootCause?.enquiries || []).find(
    (enquiry: Enquiry) => {
      return enquiry.id === selectedEnquiryOption?.value;
    }
  );

  let subEnquiries = (selectedEnquiry?.subEnquiries || [])
    .map((subEnquiry: SubEnquiry) => {
      return {
        label: subEnquiry.value,
        value: subEnquiry.id,
      };
    })
    .sort(labelSorter);

  const handleAddTag = (tag: string) => {
    const newTags = currentTags || [];

    form.setValue(
      "tags",
      newTags.concat([
        {
          label: renderTag(highlightedText, tag),
          value: {
            text: highlightedText,
            tag: tag,
          },
        },
      ])
    );
  };

  const currentKeywords = form.watch("keywords");
  const addKeyword = (keyword: string) => {
    const newKeywords = currentKeywords || [];

    form.setValue(
      "keywords",
      newKeywords.concat([
        {
          label: keyword,
          value: keyword,
        },
      ])
    );
  };

  return (
    <Container>
      {showNewTagModal && (
        <NewTagModal
          isOpen={showNewTagModal}
          highlightedText={highlightedText}
          addTagHandler={(tag: string) => handleAddTag(tag)}
          onRequestClose={() => {
            setShowNewTagModal(false);
          }}
        />
      )}

      <FieldGroup>
        <StyledSelectField
          name="rootCause"
          form={form}
          options={rootCauseOptions}
          creatable={true}
          required={false}
          isIntl={false}
          isClearable={true}
        />
        <StyledSelectField
          name="enquiry"
          form={form}
          options={enquiries}
          creatable={true}
          required={false}
          isIntl={false}
          isClearable={true}
        />
        <StyledSelectField
          name="subEnquiry"
          form={form}
          options={subEnquiries}
          creatable={true}
          required={false}
          isIntl={false}
          isClearable={true}
        />
        <StyledSelectField
          name="resolution"
          form={form}
          options={resolutionOptions}
          creatable={true}
          required={false}
          isIntl={false}
          isClearable={true}
        />
      </FieldGroup>
      <FieldGroup>
        <div className="flex">
          <StyledSelectField
            name="tags"
            form={form}
            options={[]}
            isMulti={true}
            required={false}
            isClearable={true}
          />
          <FormButton
            className="align-self-center"
            label="add.label"
            onClick={() => {
              const newHighlightedText =
                window.getSelection()?.toString() || "";
              setHighlightedText(newHighlightedText);
              if (newHighlightedText !== "") {
                setShowNewTagModal(true);
              }
            }}
          />
        </div>
      </FieldGroup>
      <FieldGroup>
        <div className="flex">
          <StyledSelectField
            name="keywords"
            form={form}
            options={[]}
            isMulti={true}
            creatable={true}
            isClearable={true}
            required={false}
          />

          <FormButton
            className="align-self-center"
            label="add.label"
            onClick={() => {
              const newHighlightedText =
                window.getSelection()?.toString() || "";
              if (newHighlightedText !== "") {
                addKeyword(newHighlightedText);
              }
            }}
          />
        </div>
      </FieldGroup>
      {verbatim.targetClassName && (
        <RadioGroup>
          <Radio
            name="targetClassCorrect"
            label={`Is the target class value correct?: ${verbatim.targetClassName} = ${verbatim.targetClassValue}`}
            required={false}
            form={form}
            options={[
              {
                label: "Yes",
                value: "Yes",
              },
              {
                label: "No",
                value: "No",
              },
            ]}
          />
        </RadioGroup>
      )}
    </Container>
  );
};

export default ClassificationForm;
