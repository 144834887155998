import FormTextField from "components/FormFields/FormTextField";
import styled from "styled-components";
import { SelectField } from "components/FormFields/SelectField";
import { useFieldArray } from "react-hook-form";
import { Button, Heading3 } from "sbui";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { User, Workspace } from "types";
import Loading from "sbui/components/loading";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
`;

const AllocationRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

const StyledSelectField = styled(SelectField)`
  width: 70%;
  padding: 10px;
`;

const StyledAllocationHeading = styled(Heading3)<{ overAllocated: boolean }>`
  color: ${(props) => (props.overAllocated ? "red" : "green")};
`;

const StyledRemoveButton = styled(Button)`
  height: 60px;
`;

const GET_USERS = gql`
  query users {
    users {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_WORKSPACES = gql`
  query workspaces($organisationId: Float!) {
    workspaces(organisationId: $organisationId) {
      id
      name
      type
      verbatimCount
      organisationId
      createdAt
      owner {
        id
        firstName
        email
      }
    }
  }
`;

type AllocateWorkspacesFormProps = {
  organisationId: string;
  maxAllocationTotal: number;
  form: any;
};

const AllocateWorkspacesForm = ({
  maxAllocationTotal,
  organisationId,
  form,
}: AllocateWorkspacesFormProps) => {
  const { loading: usersLoading, data: usersData } = useQuery(GET_USERS);
  const { loading: workspacesLoading, data: workspacesData } = useQuery(
    GET_WORKSPACES,
    {
      variables: {
        organisationId: organisationId,
      },
    }
  );

  const {
    formState: { errors, isSubmitted },
  } = form;

  const users = form.watch("users");
  const allocations = form.watch("allocations");

  const {
    fields: allocationFields,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: "allocations",
  });

  if (usersLoading || workspacesLoading) {
    return <Loading />;
  }

  const amountToAllocate = maxAllocationTotal;
  const allocatedAmount = allocations.reduce(
    (acc: any, field: any) => acc + (parseInt(field.allocated) || 0),
    0
  );

  const setAllocations = () => {
    const numberOfUsers = users.length;
    const equalAllocation = Math.floor(amountToAllocate / numberOfUsers);
    const remainder = amountToAllocate % numberOfUsers;

    const allocations = users.map((u: any, index: number) => ({
      user: u,
      allocated:
        index === numberOfUsers - 1
          ? equalAllocation + remainder
          : equalAllocation,
    }));

    form.setValue("allocations", allocations as any);
  };

  const userOptions = usersData?.users.map((user: User) => {
    return {
      label: `${user.firstName} ${user.lastName} <${user.email}>`,
      value: user.id,
    };
  });

  const workspaceOptions = workspacesData?.workspaces.map(
    (workspace: Workspace) => {
      return {
        label: workspace.name,
        value: workspace,
      };
    }
  );

  const overAllocated = allocatedAmount > amountToAllocate;

  return (
    <Container>
      <StyledAllocationHeading overAllocated={overAllocated}>
        Allocated amount: {allocatedAmount}/{amountToAllocate}
      </StyledAllocationHeading>
      {isSubmitted &&
        errors &&
        errors.allocations &&
        errors.allocations.root && (
          <StyledAllocationHeading overAllocated={true}>
            {errors.allocations?.root?.message}
          </StyledAllocationHeading>
        )}
      {allocationFields.length < 1 && (
        <>
          <StyledSelectField
            name="users"
            form={form}
            options={userOptions}
            creatable={true}
            required={false}
            isIntl={false}
            isClearable={true}
            isMulti={true}
          />
          <Button
            label="workspaces.allocate.label"
            primary={true}
            onClick={() => setAllocations()}
          />
        </>
      )}
      {allocationFields.map((field, index) => (
        <AllocationRowContainer>
          <StyledSelectField
            name={`allocations.${index}.user`}
            label={"user.label"}
            form={form}
            options={userOptions}
            creatable={false}
            required={false}
            isIntl={false}
            isClearable={true}
          />
          <StyledSelectField
            name={`allocations.${index}.workspace`}
            label={"workspace.label"}
            form={form}
            options={workspaceOptions}
            creatable={true}
            required={false}
            isIntl={false}
            isClearable={true}
            isMulti={false}
            errorSelector={`allocations.${index}.workspace.value.message`}
          />
          <FormTextField
            key={field.id}
            label={"workspaces.allocatedCount.label"}
            name={`allocations.${index}.allocated`}
            form={form}
          />
          <StyledRemoveButton
            label="delete.label"
            onClick={() => remove(index)}
          />
        </AllocationRowContainer>
      ))}
      {allocationFields.length > 0 && (
        <Button
          label="workspaces.addAnotherAllocation.label"
          onClick={() => append({ user: null, allocated: 0 })}
          primary={true}
        />
      )}
    </Container>
  );
};

export default AllocateWorkspacesForm;
