import { Tokens } from "types";
const TOKEN_KEY = "tokens";

export const isAuthenticated = () => {
  const token = localStorage.getItem(TOKEN_KEY);

  return token !== null;
};

export const getTokens = (): Tokens => {
  return JSON.parse(localStorage.getItem(TOKEN_KEY) || "{}");
};

export const login = (tokens: Tokens) => {
  logout();
  localStorage.setItem(TOKEN_KEY, JSON.stringify(tokens));
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
