import React from "react";
import FormTextField from "components/FormFields/FormTextField";
import { SelectField } from "components/FormFields/SelectField";

import styled from "styled-components";
import { ROLE_OPTIONS } from "utils/roles";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 33vw;
  padding-bottom: 10px;
`;

const UserForm = ({ form }: any) => {
  return (
    <Container>
      <FormTextField name="firstName" form={form} />
      <FormTextField name="lastName" form={form} />
      <FormTextField name="email" type="email" form={form} />
      <SelectField name="role" form={form} options={ROLE_OPTIONS} />
    </Container>
  );
};

export default UserForm;
