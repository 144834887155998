import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 33vw;
  padding-bottom: 10px;
`;

const ExportVerbatimsForm = ({ organisationId, workspaceId, form }: any) => {
  // const { errors } = form.formState;

  return <Container></Container>;
};

export default ExportVerbatimsForm;
