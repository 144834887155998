import React from "react";
import FormTextField from "components/FormFields/FormTextField";
import styled from "styled-components";
import { SelectField } from "components/FormFields/SelectField";
import { WORKSPACE_OPTIONS } from "utils/workspace";
import { gql, useQuery } from "@apollo/client";
import { User } from "types";
import Loading from "sbui/components/loading";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 33vw;
  padding-bottom: 10px;
`;

const GET_USERS = gql`
  query users {
    users {
      id
      firstName
      lastName
      email
    }
  }
`;

const WorkspaceForm = ({ form }: any) => {
  const { loading, data } = useQuery(GET_USERS);

  if (loading) {
    return <Loading />;
  }

  const userOptions = data?.users.map((user: User) => {
    return {
      label: `${user.firstName} ${user.lastName} <${user.email}>`,
      value: user.id,
    };
  });

  return (
    <Container>
      <FormTextField name="name" form={form} />
      <SelectField name="type" form={form} options={WORKSPACE_OPTIONS} />
      <SelectField
        name={"owner"}
        label={"Owner"}
        form={form}
        options={userOptions}
        creatable={true}
        required={false}
        isIntl={false}
        isClearable={true}
      />
    </Container>
  );
};

export default WorkspaceForm;
