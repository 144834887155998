import React, { Suspense } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import Login from "./Login";
import Loading from "../sbui/components/loading";
import UserShell from "components/UserShell";
import { isAuthenticated } from "utils/auth";
import ForgotPassword from "./ForgotPassword";
import SetPassword from "./SetPassword";
import Logout from "./Logout";

const PrivateRoute = (props: any) => {
  if (isAuthenticated()) {
    return <Route {...props} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { referrer: props.location.pathname },
        }}
      />
    );
  }
};

const PrivateRouteWithRouter = withRouter(PrivateRoute);

const Routes = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/set-password/:code" component={SetPassword} />
        <Route path="/logout" component={Logout} />
        <PrivateRouteWithRouter path="/" component={UserShell} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
