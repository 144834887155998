const currentEndpoint =
  process.env.REACT_APP_API_URL || "http://localhost:4000";

interface Config {
  API_ENDPOINT: string;
  API_WS_ENDPOINT: string;
}

export const config: Config = {
  API_ENDPOINT: currentEndpoint,
  API_WS_ENDPOINT: currentEndpoint
    .replace("https://", "wss://")
    .replace("http://", "ws://"),
};
