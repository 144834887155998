import React from "react";

import gql from "graphql-tag";
import { Modal2 } from "sbui";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import WorkspaceForm from "./WorkspaceForm";
import { GET_ORGANISATION, GET_WORKSPACES } from "./index";
import { WorkspaceType } from "types";

const CREATE_WORKSPACE = gql`
  mutation createWorkspace($input: CreateWorkspaceInput!) {
    createWorkspace(input: $input) {
      id
    }
  }
`;

type Inputs = {
  name: string;
  type: { label: string; value: WorkspaceType };
  owner: { label: string; value: string };
};

const NewWorkspaceModal = ({ isOpen, organisationId, onRequestClose }: any) => {
  const { handleSubmit, ...form } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    createWorkspace({
      variables: {
        input: {
          organisationId: organisationId,
          name: data.name,
          type: data.type.value,
          ownerId: parseInt(data.owner.value),
        },
      },
    });
  };

  const [createWorkspace, { loading }] = useMutation(CREATE_WORKSPACE, {
    onError: (err) => {
      if (err.graphQLErrors && err.graphQLErrors.length >= 1) {
        form.setError("name", {
          type: "manual",
          message: err.graphQLErrors[0].message,
        });
      }
    },
    onCompleted: (res) => {
      onRequestClose();
    },
    refetchQueries: [
      {
        query: GET_ORGANISATION,
        variables: {
          organisationId: organisationId,
        },
      },
      {
        query: GET_WORKSPACES,
        variables: {
          organisationId: organisationId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <Modal2
      titleText="workspaces.add.label"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
    >
      <WorkspaceForm onSubmit={onRequestClose} form={form} />
    </Modal2>
  );
};

export default withRouter(NewWorkspaceModal);
