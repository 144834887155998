import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { config } from "./config";

import history from "./history";
import { getTokens, login } from "./auth";

const cache = new InMemoryCache({});
const httpLink = new HttpLink({
  uri: config.API_ENDPOINT + "/graphql",
});

const unauthLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response.errors && response.errors[0]) {
      const message = response.errors[0].message as any;
      if (message === "Not Authorised!") {
        history.push({
          pathname: "/logout",
          state: { referrer: history.location.pathname },
        });
      }
    }
    return response;
  });
});

const sessionExtenderMiddleware = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const accessToken = headers.get("x-access-token");
      const refreshToken = headers.get("x-refresh-token");
      if (accessToken && refreshToken) {
        login({
          accessToken: accessToken,
          refreshToken: refreshToken,
        });
      }
    }
    return response;
  });
});

const asyncAuthLink = setContext(
  (request) =>
    new Promise((success, fail) => {
      const tokens = getTokens();
      success({
        headers: {
          "x-access-token": tokens.accessToken,
          "x-refresh-token": tokens.refreshToken,
        },
      });
    })
);

export const client = new ApolloClient({
  cache,
  link: from([asyncAuthLink, unauthLink, sessionExtenderMiddleware, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
});
