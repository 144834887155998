import React from "react";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";

import styled from "styled-components";
import { useIntl } from "react-intl";

const classNamePrefix = "react-select";
const StyledReactSelect = styled(ReactSelect)`
  width: 100%;
`;

const CreatableStyledReactSelect = styled(CreatableSelect)`
  width: 100%;
`;

const FieldLabel = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.medium};
  margin-left: ${(props) => props.theme.spacing.xsmall};
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: ${(props) => props.theme.font.colors.error};
`;

const HelperText = styled.div`
  color: #bbb;
  padding: 10px;
`;

const Select = ({
  label,
  placeholder,
  labelValues,
  touched,
  helperText,
  error,
  creatable,
  className,
  ...props
}: any) => {
  const { formatMessage } = useIntl();

  let labelView;
  if (label) {
    const intlLabel = formatMessage({ id: label }, labelValues || {});
    labelView = <FieldLabel>{intlLabel}</FieldLabel>;
  }

  let placeholderValue = placeholder;
  if (placeholder) {
    const intlLabel = formatMessage({ id: placeholder }, {});
    placeholderValue = <FieldLabel>{intlLabel}</FieldLabel>;
  }

  let errorMessage = "\u00A0";
  if (touched && error) {
    errorMessage = formatMessage({ id: error }, labelValues || {});
  }

  const SelectClass = creatable
    ? CreatableStyledReactSelect
    : StyledReactSelect;

  return (
    <SelectContainer data-test={props.name} className={className}>
      {labelView}
      <SelectClass
        styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        classNamePrefix={classNamePrefix}
        placeholder={placeholderValue}
        isDisabled={props.disabled}
        {...props}
      />
      {helperText && <HelperText>{helperText}</HelperText>}
      <ErrorLabel>{errorMessage}</ErrorLabel>
    </SelectContainer>
  );
};

export default Select;
