import React from "react";
import { TextField } from "sbui";

const FormTextField = ({
  name,
  label,
  noLabel = false,
  noMargin = false,
  form,
  registerArgs,
  error,
  required = true,
  ...rest
}: any) => {
  const {
    register,
    formState: { errors, touchedFields, isSubmitted },
  } = form;

  let errorMessage = null;
  if (errors[name] && errors[name].type === "required") {
    errorMessage = "generic.error.required";
  } else if (errors[name] && errors[name].type === "validate") {
    errorMessage = errors[name].message;
  } else if (errors[name] && errors[name].type === "manual") {
    errorMessage = errors[name].message;
  } else {
    errorMessage = error;
  }

  return (
    <TextField
      label={noLabel ? null : label || `${name}.label`}
      noMargin={noMargin}
      touched={touchedFields[name] || isSubmitted}
      error={errorMessage}
      {...register(name, registerArgs ? registerArgs : { required })}
      {...rest}
    />
  );
};

export default FormTextField;
