import React from "react";
import { useBreadcrumbsState, BreadcrumbItem } from "./Context";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const BreadcrumbView = ({ breadcrumb }: { breadcrumb: BreadcrumbItem }) => {
  const intl = useIntl();
  const location = useLocation();
  const active = location.pathname === breadcrumb.to;

  const label: string = breadcrumb.label.includes(".")
    ? intl.formatMessage({ id: breadcrumb.label }, breadcrumb.values)
    : breadcrumb.label;

  const navLinkOrLabel = breadcrumb.to ? (
    <Link href={breadcrumb.to}>{label}</Link>
  ) : (
    label
  );

  return <div>{active ? label : navLinkOrLabel}</div>;
};

export const BreadcrumbsView = () => {
  const { breadcrumbs } = useBreadcrumbsState();
  const breadcrumbViews = breadcrumbs.map<React.ReactNode>(
    (breadcrumb: BreadcrumbItem) => (
      <BreadcrumbView key={breadcrumb.label} breadcrumb={breadcrumb} />
    )
  );

  return <Breadcrumbs aria-label="breadcrumb">{breadcrumbViews}</Breadcrumbs>;
};
