import React from "react";
import styled from "styled-components";

import { useIntl } from "react-intl";
import RegularText from "../typography/RegularText";

type StyledTextFieldProps = {
  fullWidth?: boolean;
  marginOverride?: string;
};

const StyledTextField = styled.input`
  flex: ${(props: StyledTextFieldProps) => (props.fullWidth ? "1" : "none")};
  padding: ${(props) =>
    props.marginOverride ? props.marginOverride : props.theme.input.padding};
  font-size: ${(props) => props.theme.input.font.size};
  font-family: ${(props) => props.theme.font.primary};

  background: #ffffff;
  border: 2px solid ${(props) => props.theme.input.borderColor};
  border-radius: 4px;
`;

const StyledTextArea = styled.textarea`
  flex: ${(props: StyledTextFieldProps) => (props.fullWidth ? "1" : "none")};
  padding: ${(props) => props.theme.input.padding};
  font-size: ${(props) => props.theme.input.font.size};
`;

const FieldLabel = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.medium};
  margin-left: ${(props) => props.theme.spacing.xsmall};

  color: #333;
`;

const FieldContainer = styled.div`
  display: flex;
  flex: ${(props: StyledTextFieldProps) => (props.fullWidth ? "1" : "none")};
  flex-direction: ${(props: StyledTextFieldProps) =>
    props.fullWidth ? "column" : "none"};
`;

const ErrorLabel = styled.div`
  color: ${(props) => props.theme.font.colors.error};
`;

const HintLabel = styled(RegularText)`
  margin-top: 5px;
`;

export type TextFieldProps = {
  fullWidth?: boolean;
  label?: string;
  values?: any;
  error?: string;
  marginOverride?: string;
  touched?: boolean;
  className?: string;
  hintLabel?: string;
  multiline?: boolean;
} & JSX.IntrinsicElements["input"] &
  JSX.IntrinsicElements["textarea"];

const TextField = React.forwardRef(
  (
    {
      fullWidth = true,
      label,
      values,
      touched,
      error,
      className,
      name,
      hintLabel,
      marginOverride,
      multiline = false,
      ...props
    }: TextFieldProps,
    ref
  ) => {
    const { formatMessage } = useIntl();
    const textField = multiline ? (
      <StyledTextArea
        ref={ref as any}
        name={name}
        data-test={`${name}`}
        fullWidth={fullWidth}
        {...props}
      />
    ) : (
      <StyledTextField
        ref={ref as any}
        name={name}
        fullWidth={fullWidth}
        data-test={`${name}`}
        marginOverride={marginOverride}
        type="text"
        {...props}
      />
    );

    let labelView;
    if (label) {
      const intlLabel = formatMessage({ id: label }, values || {});
      labelView = <FieldLabel>{intlLabel}</FieldLabel>;
    }

    let errorMessage = "\u00A0";
    if (touched && error) {
      errorMessage = formatMessage({ id: error }, values || {});
    }

    return (
      <FieldContainer className={className} fullWidth={fullWidth}>
        {labelView}
        {textField}
        {hintLabel && <HintLabel label={hintLabel} />}
        <ErrorLabel>{errorMessage}</ErrorLabel>
      </FieldContainer>
    );
  }
);

export default TextField;
