import React, { useEffect, useState } from "react";
import { Dashboard } from "@uppy/react";
import Loading from "sbui/components/loading";
import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";

const Uppy = require("@uppy/core");
const AwsS3 = require("@uppy/aws-s3");

const NEW_FILE_UPLOAD = gql`
  query newFileUpload($input: NewFileUploadInput!) {
    newFileUpload(input: $input) {
      url
      method
      fields {
        key
        value
      }
      headers {
        key
        value
      }
    }
  }
`;

const ErrorLabel = styled.div`
  color: ${(props: any) => props.theme.font.colors.error};
`;

const FileUpload = ({
  onFileUpload,
  error,
  organisationId,
  workspaceId,
}: any) => {
  const { loading, data } = useQuery(NEW_FILE_UPLOAD, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        organisationId,
        workspaceId,
      },
    },
  });

  const [uppy] = useState(
    Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
      },
    })
  );

  useEffect(() => {
    uppy.use(AwsS3, {
      getUploadParameters: () => {
        const { headers, fields, ...rest } = data?.newFileUpload;

        let squashedHeaders: any = {};
        headers.forEach((header: any) => {
          squashedHeaders[header.key] = header.value;
        });

        let squashedFields: any = {};
        fields.forEach((field: any) => {
          squashedFields[field.key] = field.value;
        });

        return {
          headers: squashedHeaders,
          fields: squashedFields,
          ...rest,
        };
      },
    });

    const onComplete = (result: any) => {
      onFileUpload(result.successful);
    };

    uppy.on("complete", onComplete);

    return () => {
      uppy.off("complete", onComplete);

      const s3Plugin = uppy.getPlugin("AwsS3");
      uppy.removePlugin(s3Plugin);
    };
  }, [uppy, data, onFileUpload]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Dashboard uppy={uppy} proudlyDisplayPoweredByUppy={false} height={200} />
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </div>
  );
};

export default FileUpload;
