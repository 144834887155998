import React from "react";
import styled, { css } from "styled-components";
import { withRouter } from "react-router-dom";
import { useIntl } from "react-intl";
import Logo from "components/Logo";

const SidebarContainer = styled.div<{ isMobile: boolean }>`
  background-color: ${(props) => props.theme.sidebar.backgroundColor};
  border-right: 2px solid #e8ecef;
  ${(props) =>
    props.isMobile &&
    css`
      flex: 1;
    `}
`;
const SidebarHeader = styled.div``;

const SidebarHeaderLogo = styled(Logo)`
  height: 30px;
  margin: ${(props) => props.theme.spacing.large};
  padding: 10px;
`;

const SidebarMenu = styled.ul`
  color: ${(props) => props.theme.sidebar.color};
`;
const SidebarMenuItem = styled.li<{ active: boolean }>`
  width: 100%;
  padding: ${(props) => props.theme.spacing.xxlarge};
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.sidebar.itemHighlightedColor};
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.sidebar.itemHighlightedColor};
      border-left: 3px solid ${(props) => props.theme.sidebar.borderColor};
    `}
`;

function Sidebar({
  menuItems,
  history,
  location,
  isMobile,
  onMenuItemClick,
}: any) {
  const intl = useIntl();
  const { pathname } = location;

  const sidebarMenuItems: any = menuItems.map((menuItem: any) => {
    return (
      <SidebarMenuItem
        key={menuItem.title}
        className={menuItem.className}
        onClick={() => {
          onMenuItemClick();
          history.push(menuItem.path);
        }}
        active={pathname === menuItem.path}
      >
        {intl.formatMessage({ id: menuItem.title }, menuItem.values)}
      </SidebarMenuItem>
    );
  });

  return (
    <SidebarContainer isMobile={isMobile}>
      <SidebarHeader>
        <SidebarHeaderLogo />
      </SidebarHeader>
      <SidebarMenu>{sidebarMenuItems}</SidebarMenu>
    </SidebarContainer>
  );
}

export default withRouter(Sidebar);
