import React from "react";
import styled, { css } from "styled-components";
import RegularText from "./RegularText";
import { useIntl } from "react-intl";

const FieldContainer = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0px;
  border-bottom: 1px solid #ccc;

  ${(props) =>
    !props.fullWidth &&
    css`
      flex: 0 50%;
      width: 75%;
    `}
`;

const FieldHeader = styled.h5`
  color: ${(props) => props.theme.table.singleColumn.colors.fieldHeader};
  font-size: 16px;
  width: 100%;
`;

const FieldText = styled(RegularText)`
  text-align: center;
  width: 100%;
`;

const LabelledText = ({ label, children, text, className, fullWidth }: any) => {
  const intl = useIntl();
  if (!children && !text) {
    children = "-";
  }

  return (
    <FieldContainer className={className} fullWidth={fullWidth}>
      <FieldHeader>
        {label ? intl.formatMessage({ id: label }) : label}
      </FieldHeader>
      <FieldText children={children} label={text} />
    </FieldContainer>
  );
};

export default LabelledText;
