import React from "react";
import styled from "styled-components";

import { useIntl } from "react-intl";

const StyledRegularText = styled.span``;

export type RegularTextProps = {
  label?: string;
  values?: any;
} & JSX.IntrinsicElements["span"];

const RegularText = ({
  label,
  values,
  ref,
  children,
  ...props
}: RegularTextProps) => {
  const { formatMessage } = useIntl();
  const intlLabel =
    !children && label ? formatMessage({ id: label }, values || {}) : children;

  return (
    <StyledRegularText ref={ref as any} {...props}>
      {intlLabel}
    </StyledRegularText>
  );
};

export default RegularText;
