import React from "react";
import { ToggleLayer, useBreakpoint, Transition } from "react-laag";
import ResizeObserver from "resize-observer-polyfill";
import { useSelect } from "downshift";
import styled, { css } from "styled-components";
import { useIntl } from "react-intl";

const shared = css`
  background-color: white;
  margin: 0;
  padding: 0;
  list-style: none;
`;

type MenuItemProps = {
  active: boolean;
};

type MenuProps = {
  isOpen: boolean;
  isMobile: boolean;
};

export const MobileMenu = styled.ul<MenuProps>`
  ${shared}
  max-height: 75vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 0px 16px;
  border-top: 1px solid ${(props) => props.theme.dropdown.borderColor};
  box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.08);

  ${(props) =>
    props.isMobile &&
    css`
      transition: transform 0.2s;
    `}

  ${(props) =>
    props.isMobile &&
    props.isOpen &&
    css`
      transform: translateY(0%);
    `}

    ${(props) =>
    props.isMobile &&
    !props.isOpen &&
    css`
      transform: translateY(100%);
    `}
`;

export const DesktopMenu = styled.ul<MenuProps>`
  ${shared}
  width: 170px;
  border-radius: 4px;
  padding: 8px 0px;
  border: 1px solid ${(props) => props.theme.dropdown.borderColor};
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);

  ${(props) =>
    !props.isMobile &&
    css`
      transition: opacity 0.2s;
    `}

  ${(props) =>
    !props.isMobile &&
    props.isOpen &&
    css`
      opacity: 1;
    `}

    ${(props) =>
    !props.isMobile &&
    !props.isOpen &&
    css`
      opacity: 0;
    `}
`;

export const DesktopMenuItem = styled.li<MenuItemProps>`
  list-style: none;
  padding: 4px 16px;
  font-size: 14.8px;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.dropdown.hoverColor};
    `}
`;

export const MobileMenuItem = styled.li<MenuItemProps>`
  list-style: none;

  padding: 10px 16px;
  font-size: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid #ececec;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.dropdown.hoverColor};
    `}
`;

export const CloseButton = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 8px;
  box-shadow: none;
  position: absolute;
  top: 2px;
  right: 8px;
  font-size: 20px;
`;

type DropdownProps = {
  items?: any;
  onSelect?: any;
  children?: any;
};

type DropdownItem = {
  title: string;
  values?: any;
};

function Dropdown({ onSelect, items, children }: DropdownProps) {
  const intl = useIntl();
  const isMobile = useBreakpoint(500);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
  } = useSelect<DropdownItem>({
    items,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem !== undefined) {
        onSelect(selectedItem);
      }
    },
  });

  return (
    <ToggleLayer
      isOpen={isOpen}
      ResizeObserver={ResizeObserver}
      renderLayer={({ isOpen, layerProps, arrowStyle, layerSide }) => {
        return (
          <Transition isOpen={isOpen}>
            {(isOpen, onTransitionEnd) => {
              const Menu = isMobile ? MobileMenu : DesktopMenu;
              const MenuItem = isMobile ? MobileMenuItem : DesktopMenuItem;

              return (
                <Menu
                  onTransitionEnd={onTransitionEnd}
                  {...getMenuProps({ ref: layerProps.ref })}
                  isMobile={isMobile}
                  isOpen={isOpen}
                  style={
                    isMobile
                      ? {}
                      : {
                          ...layerProps.style,
                        }
                  }
                >
                  {isMobile && <CloseButton onClick={closeMenu}>✕</CloseButton>}

                  {items.map((option: DropdownItem, index: number) => (
                    <MenuItem
                      active={highlightedIndex === index}
                      key={`${option}${index}`}
                      data-test={`drop-down-menu-item`}
                      {...getItemProps({ item: option, index })}
                    >
                      {intl.formatMessage({ id: option.title }, option.values)}
                    </MenuItem>
                  ))}
                </Menu>
              );
            }}
          </Transition>
        );
      }}
      placement={{
        anchor: "LEFT_CENTER",
        autoAdjust: true,
        triggerOffset: 12,
        scrollOffset: 16,
      }}
      fixed={isMobile}
      onDisappear={() => {
        if (isMobile) {
          closeMenu();
        }
      }}
    >
      {({ isOpen, triggerRef }) => (
        <div {...getToggleButtonProps({ ref: triggerRef })}>
          {children({ isOpen })}
        </div>
      )}
    </ToggleLayer>
  );
}

export default Dropdown;
