import React, { useState } from "react";
import styled from "styled-components";
import ExternalShell from "components/ExternalShell";
import { RegularText } from "sbui";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { login } from "utils/auth";
import { Link } from "react-router-dom";
import Error from "components/Error";
import FormTextField from "components/FormFields/FormTextField";
import SubmitButton from "components/FormFields/SubmitButton";
import Form from "components/FormFields/FormContainer";
import PasswordField from "components/FormFields/PasswordField";

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const PasswordContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const ForgotPasswordContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
`;

const AUTHENTICATE_USER = gql`
  mutation authenticateUser($input: AuthenticateUserInput!) {
    authenticateUser(input: $input) {
      accessToken
      refreshToken
      mfaRequired
    }
  }
`;

type Inputs = {
  email: string;
  password: string;
};

const Login = () => {
  const [mfaRequired, setMfaRequired] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const [codeError, setCodeError] = useState<string | null>(null);
  const [authenticateUser, { loading }] = useMutation(AUTHENTICATE_USER, {
    onError: (err) => {
      const errorMessage = err?.graphQLErrors[0]?.message;
      if (errorMessage === "invalid.code") {
        setCodeError("login.error." + errorMessage);
      } else if (errorMessage === "mfa.required") {
        setMfaRequired(true);
      } else {
        setLoginError("login.error." + errorMessage);
      }
    },
    onCompleted: ({ authenticateUser }) => {
      if (authenticateUser?.accessToken) {
        setLoginError(null);
        login(authenticateUser);
        window.location.href = "/";
      } else {
        setLoginError("login.error.invalid.login");
      }
    },
  });

  const { handleSubmit, ...form } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    setLoginError(null);
    authenticateUser({
      variables: {
        input: data,
      },
    });
  };

  return (
    <ExternalShell>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormTextField name="email" form={form} />
          <PasswordField form={form} />
          {loginError && <Error error={loginError} />}

          {mfaRequired && (
            <>
              <FormTextField name="code" hintLabel="code.hint" form={form} />
              {codeError && <Error error={codeError} />}
            </>
          )}

          <PasswordContainer>
            <ForgotPasswordContainer>
              <Link to="/forgot-password">
                <RegularText label="login.forgot.label" />
              </Link>
            </ForgotPasswordContainer>
          </PasswordContainer>

          <SubmitButton loading={loading} label="login.submit.label" />
        </Form>
      </Container>
    </ExternalShell>
  );
};

export default Login;
