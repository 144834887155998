import React from "react";

import gql from "graphql-tag";
import { Modal2 } from "sbui";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import UserForm from "./UserForm";
import { GET_USERS } from "./index";

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;

type Inputs = {
  email: string;
  role: {
    value: string;
    label: string;
  };
  firstName: string;
  lastName: string;
};

const NewUserModal = ({ isOpen, onRequestClose }: any) => {
  const { handleSubmit, ...form } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    createUser({
      variables: {
        input: {
          role: data.role?.value,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
        },
      },
    });
  };

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    onError: (err) => {
      if (err.graphQLErrors && err.graphQLErrors.length >= 1) {
        form.setError("email", {
          type: "manual",
          message: err.graphQLErrors[0].message,
        });
      }
    },
    onCompleted: (res) => {
      onRequestClose();
    },
    refetchQueries: [
      {
        query: GET_USERS,
      },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <Modal2
      titleText="users.add.label"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
    >
      <UserForm onSubmit={onRequestClose} form={form} />
    </Modal2>
  );
};

export default withRouter(NewUserModal);
