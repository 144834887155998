import React, { useState } from "react";
import gql from "graphql-tag";
import ExternalShell from "components/ExternalShell";
import styled from "styled-components";
import { RegularText, Heading1 } from "sbui";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import Error from "components/Error";
import FormTextField from "components/FormFields/FormTextField";
import SubmitButton from "components/FormFields/SubmitButton";
import Form from "components/FormFields/FormContainer";

const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input)
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

type Inputs = {
  email: string;
};

const ForgotPassword = () => {
  const [showThanksMessage, setShowThanksMessage] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);

  const [requestPasswordReset, { loading }] = useMutation(
    REQUEST_PASSWORD_RESET,
    {
      onError: (err) => {
        console.log("err", err);
        setEmailError(
          "requestPasswordReset.error." + err?.graphQLErrors[0]?.message
        );
      },
      onCompleted: ({ requestPasswordReset }: any) => {
        if (requestPasswordReset) {
          setShowThanksMessage(true);
        } else {
          setEmailError("requestPasswordReset.error.invalid.email");
        }
      },
    }
  );

  const { handleSubmit, ...form } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    setEmailError(null);
    requestPasswordReset({
      variables: {
        input: {
          email: data.email,
        },
      },
    });
  };

  return (
    <ExternalShell>
      <Container>
        {showThanksMessage ? (
          <RegularText label="requestPasswordReset.thanks" />
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Heading1 label="requestPasswordReset.title" />

            <FormTextField name="email" form={form} />
            {emailError && <Error error={emailError} />}

            <SubmitButton
              label="requestPasswordReset.button.label"
              loading={loading}
            />
          </Form>
        )}
      </Container>
    </ExternalShell>
  );
};

export default ForgotPassword;
