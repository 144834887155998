// @ts-nocheck
import React from "react";
import styled, { css } from "styled-components";
import { useIntl } from "react-intl";

const CheckboxContainer = styled.label`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${(props) => props.theme.checkbox.iconColor};
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  border-radius: 4px;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ disabled?: boolean }>`
  display: inline-block;
  width: ${(props) => props.theme.checkbox.size};
  height: ${(props) => props.theme.checkbox.size};
  margin-right: 10px;
  border: 1px solid ${(props) => props.theme.checkbox.backgroundColor};
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${(props) => props.theme.checkbox.focusColor};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }

  ${(props) =>
    props.disabled &&
    css`
      background: #ccc;
    `}
`;

const Label = styled.div`
  margin-right: ${(props) => props.isReverse && props.theme.spacing.medium};
  margin-bottom: ${(props) => props.isColumn && props.theme.spacing.medium};
`;

const CheckboxLabelContainer = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    (props.isColumn ? "column" : "row") + (props.isReverse ? "-reverse" : "")};
`;

export type CheckboxProps = {
  className?: string;
  checked?: boolean;
  label?: string;
  disabled?: boolean;
  values?: any;
} & JSX.IntrinsicElements["checkbox"];

const Checkbox = React.forwardRef(
  (
    {
      className,
      checked,
      label,
      values,
      isColumn,
      disabled,
      isReverse,
      ...props
    }: CheckboxProps,
    forwardRef
  ) => {
    const { formatMessage } = useIntl();

    const formattedlabel = label ? formatMessage({ id: label }, values) : "";

    return (
      <CheckboxContainer className={className}>
        <HiddenCheckbox
          checked={checked}
          {...props}
          disabled={disabled}
          ref={forwardRef}
        />
        <CheckboxLabelContainer isColumn={isColumn} isReverse={isReverse}>
          <StyledCheckbox checked={checked} disabled={disabled}>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
          <Label isColumn={isColumn} isReverse={isReverse}>
            {formattedlabel}
          </Label>
        </CheckboxLabelContainer>
      </CheckboxContainer>
    );
  }
);

export default Checkbox;
