import { gql, useQuery } from "@apollo/client";
import { useBreadcrumbsDispatch } from "components/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { Button, Card } from "sbui";
import Loading from "sbui/components/loading";
import DeleteOrganisationModal from "./DeleteOrganisationModal";
import NewOrganisationModal from "./NewOrganisationModal";
import OrganisationsTable from "./OrganisationsTable";

export const GET_ORGANISATIONS = gql`
  query organisations {
    organisations {
      id
      name
      createdAt
      updatedAt
      verbatimCount
    }
  }
`;

const Organisations = () => {
  const { loading, data } = useQuery(GET_ORGANISATIONS);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [newOrganisationModalOpen, setNewOrganisationModalOpen] =
    useState(false);
  const [deleteOrganisationModalOpen, setDeleteOrganisationModalOpen] =
    useState(false);

  const dispatch = useBreadcrumbsDispatch();
  useEffect(() => {
    if (data) {
      dispatch({
        type: "setBreadcrumbs",
        value: [
          {
            label: "organisations.heading",
            to: "/organisations",
          },
        ],
      });
    }
  }, [dispatch, data]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Card
      heading="organisations.heading"
      actions={
        <Button
          primary
          label="organisations.add.label"
          onClick={() => {
            setNewOrganisationModalOpen(true);
          }}
        />
      }
    >
      {newOrganisationModalOpen && (
        <NewOrganisationModal
          isOpen={newOrganisationModalOpen}
          onRequestClose={() => {
            setNewOrganisationModalOpen(false);
          }}
        />
      )}

      {deleteOrganisationModalOpen && (
        <DeleteOrganisationModal
          isOpen={deleteOrganisationModalOpen}
          organisation={selectedOrganisation}
          onRequestClose={() => {
            setDeleteOrganisationModalOpen(false);
          }}
        />
      )}

      <OrganisationsTable
        data={data?.organisations || []}
        deleteHandler={(organisation: any) => {
          setSelectedOrganisation(organisation);
          setDeleteOrganisationModalOpen(true);
        }}
      />
    </Card>
  );
};

export default Organisations;
