import React from "react";
import ReactTooltip from "react-tooltip";
import moment from "moment";

const FromNowCell = ({ date }: any) =>
  date ? (
    <div
      data-tip={`${moment(date).format("DD/MM/yyyy")} ${moment(date).format(
        "HH:mm:ss A"
      )}`}
      data-tip-disable={false}
    >
      {moment(date).fromNow()}
      <ReactTooltip />
    </div>
  ) : (
    <div>-</div>
  );

export default FromNowCell;
