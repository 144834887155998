import React from "react";
import OILogo from "assets/images/logo.svg";

interface LogoProps {
  className?: string;
}

const Logo = ({ className }: LogoProps) => (
  <img className={className} alt="OI Logo" src={OILogo} />
);

export default Logo;
