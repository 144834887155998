import React from "react";
import styled from "styled-components";
import { Radio } from "sbui";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 33vw;
  padding-bottom: 10px;
`;

const MergeReferenceDataForm = ({
  typeLabel,
  type,
  form,
  referenceData,
  valueMap,
}: any) => {
  const options = referenceData.map((row: any) => {
    return {
      label: valueMap[`${type}:${row.id}`],
      value: row.id,
    };
  });

  return (
    <Container>
      <Radio
        name={"referenceData"}
        label={`Select a ${typeLabel} to merge the others into`}
        required={true}
        form={form}
        options={options}
      />
    </Container>
  );
};

export default MergeReferenceDataForm;
