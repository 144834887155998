import { gql, useQuery } from "@apollo/client";
import { useBreadcrumbsDispatch } from "components/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { Button, Card } from "sbui";
import Loading from "sbui/components/loading";
import { useGlobalStore } from "utils/state";
import DeleteUserModal from "./DeleteUserModal";
import KickSessionsModal from "./KickSessionsModal";
import NewUserModal from "./NewUserModal";
import UsersTable from "./UsersTable";

export const GET_USERS = gql`
  query users {
    users {
      id
      firstName
      lastName
      role
      email
    }
  }
`;

const Users = () => {
  const { loading, data } = useQuery(GET_USERS);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUserModalOpen, setNewUserModalOpen] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [kickSessionsModalOpen, setKickSessionsModalOpen] = useState(false);

  const { user: currentUser } = useGlobalStore();

  const dispatch = useBreadcrumbsDispatch();
  useEffect(() => {
    dispatch({
      type: "setBreadcrumbs",
      value: [
        {
          label: "users.heading",
        },
      ],
    });
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Card
      heading="users.heading"
      actions={
        <Button
          primary
          label="users.add.label"
          onClick={() => {
            setNewUserModalOpen(true);
          }}
        />
      }
    >
      {newUserModalOpen && (
        <NewUserModal
          isOpen={newUserModalOpen}
          onRequestClose={() => {
            setNewUserModalOpen(false);
          }}
        />
      )}

      {deleteUserModalOpen && (
        <DeleteUserModal
          isOpen={deleteUserModalOpen}
          user={selectedUser}
          onRequestClose={() => {
            setDeleteUserModalOpen(false);
          }}
        />
      )}

      {kickSessionsModalOpen && (
        <KickSessionsModal
          isOpen={kickSessionsModalOpen}
          user={selectedUser}
          onRequestClose={() => {
            setKickSessionsModalOpen(false);
          }}
        />
      )}
      <UsersTable
        currentUser={currentUser}
        data={data?.users || []}
        deleteHandler={(user: any) => {
          setSelectedUser(user);
          setDeleteUserModalOpen(true);
        }}
        kickSessionsHandler={(user: any) => {
          setSelectedUser(user);
          setKickSessionsModalOpen(true);
        }}
      />
    </Card>
  );
};

export default Users;
