import React from "react";
import styled from "styled-components";
import { SelectField } from "components/FormFields/SelectField";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 33vw;
  padding-bottom: 10px;
`;

const MoveSubEnquiriesForm = ({ form, rootCauses, referenceData }: any) => {
  const enquiryOptions = rootCauses.flatMap((rootCause: any) => {
    return (rootCause.enquiries || []).map((e: any) => {
      return {
        label: `${rootCause.value} - ${e.value}`,
        value: e.id,
      };
    });
  });

  return (
    <Container>
      <SelectField name="enquiry" form={form} options={enquiryOptions} />
    </Container>
  );
};

export default MoveSubEnquiriesForm;
