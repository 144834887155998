export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** Arbitrary object */
  Object: any;
};

export type Query = {
  __typename?: 'Query';
  me: User;
  users: Array<User>;
  accessLogs: AccessLogsResponse;
  organisations: Array<Organisation>;
  organisation: Organisation;
  workspace: Workspace;
  workspaces: Array<Workspace>;
  verbatims: VerbatimsResponse;
  newFileUpload: NewFileUpload;
  rootCauses: Array<RootCause>;
  referenceDataVerbatimCount: Array<ReferenceDataVerbatimCount>;
  tags: Array<Tag>;
};


export type QueryAccessLogsArgs = {
  input: PageDetailsInput;
};


export type QueryOrganisationArgs = {
  organisationId: Scalars['Float'];
};


export type QueryWorkspaceArgs = {
  workspaceId: Scalars['Float'];
};


export type QueryWorkspacesArgs = {
  organisationId: Scalars['Float'];
};


export type QueryVerbatimsArgs = {
  input: GetVerbatimsInput;
};


export type QueryNewFileUploadArgs = {
  input: NewFileUploadInput;
};


export type QueryRootCausesArgs = {
  input: GetRootCausesInput;
};


export type QueryReferenceDataVerbatimCountArgs = {
  organisationId: Scalars['Float'];
};


export type QueryTagsArgs = {
  input?: Maybe<TagsInput>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Float'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  role: Role;
  workspaces: Array<Workspace>;
  tokenVersion: Scalars['Float'];
};

/** The role type */
export enum Role {
  Admin = 'admin',
  Staff = 'staff',
  User = 'user'
}

export type Workspace = {
  __typename?: 'Workspace';
  id: Scalars['Float'];
  name: Scalars['String'];
  type: WorkspaceType;
  verbatimCount?: Maybe<Scalars['Float']>;
  organisationId: Scalars['Float'];
  ownerId: Scalars['Float'];
  owner?: Maybe<User>;
  verbatims: Array<Verbatim>;
  createdAt: Scalars['DateTime'];
};

/** The workspace type */
export enum WorkspaceType {
  Live = 'live',
  Experimental = 'experimental'
}

export type Verbatim = {
  __typename?: 'Verbatim';
  id: Scalars['Float'];
  verbatim: Scalars['String'];
  sourceId: Scalars['String'];
  organisationId: Scalars['Float'];
  workspaceId: Scalars['Float'];
  rootCauseId?: Maybe<Scalars['Float']>;
  rootCause?: Maybe<RootCause>;
  enquiryId?: Maybe<Scalars['Float']>;
  enquiry?: Maybe<Enquiry>;
  subEnquiryId?: Maybe<Scalars['Float']>;
  subEnquiry?: Maybe<SubEnquiry>;
  targetClassName?: Maybe<Scalars['String']>;
  targetClassValue?: Maybe<Scalars['String']>;
  targetClassCorrect?: Maybe<Scalars['Boolean']>;
  status: VerbatimStatus;
  keywords?: Maybe<Array<Scalars['String']>>;
  resolution?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<VerbatimTag>>;
  changes?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
};

export type RootCause = {
  __typename?: 'RootCause';
  id: Scalars['Float'];
  organisationId: Scalars['Float'];
  enquiries: Array<Enquiry>;
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type Enquiry = {
  __typename?: 'Enquiry';
  id: Scalars['Float'];
  rootCauseId: Scalars['Float'];
  subEnquiries: Array<SubEnquiry>;
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type SubEnquiry = {
  __typename?: 'SubEnquiry';
  id: Scalars['Float'];
  enquiryId: Scalars['Float'];
  enquiry: Enquiry;
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
};


/** The verbatim status */
export enum VerbatimStatus {
  New = 'new',
  Validated = 'validated',
  CannotClassify = 'cannot_classify'
}

export type VerbatimTag = {
  __typename?: 'VerbatimTag';
  tagId: Scalars['Float'];
  verbatimId: Scalars['Float'];
  organisationId: Scalars['Float'];
  tag?: Maybe<Tag>;
  text: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['Float'];
  tag: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type AccessLogsResponse = {
  __typename?: 'AccessLogsResponse';
  items: Array<AccessLog>;
  total: Scalars['Float'];
  hasMore: Scalars['Boolean'];
};

export type AccessLog = {
  __typename?: 'AccessLog';
  userId?: Maybe<Scalars['Float']>;
  userEmail?: Maybe<Scalars['String']>;
  ipAddress: Scalars['String'];
  action: Scalars['String'];
  context?: Maybe<Scalars['Object']>;
  createdAt: Scalars['DateTime'];
};


export type PageDetailsInput = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};

export type Organisation = {
  __typename?: 'Organisation';
  id: Scalars['Float'];
  name: Scalars['String'];
  verbatimCount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  workspaces: Array<Workspace>;
  updatedAt: Scalars['DateTime'];
};

export type VerbatimsResponse = {
  __typename?: 'VerbatimsResponse';
  items: Array<Verbatim>;
  total: Scalars['Float'];
  hasMore: Scalars['Boolean'];
};

export type GetVerbatimsInput = {
  workspaceId: Scalars['Float'];
  status?: Maybe<VerbatimStatus>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  interactionId?: Maybe<Scalars['Float']>;
};

export type NewFileUpload = {
  __typename?: 'NewFileUpload';
  method: Scalars['String'];
  url: Scalars['String'];
  fields: Array<NewFileUpoadField>;
  headers: Array<NewFileUploadHeader>;
};

export type NewFileUpoadField = {
  __typename?: 'NewFileUpoadField';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type NewFileUploadHeader = {
  __typename?: 'NewFileUploadHeader';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type NewFileUploadInput = {
  organisationId: Scalars['Float'];
  workspaceId: Scalars['Float'];
};

export type GetRootCausesInput = {
  organisationId: Scalars['Float'];
};

export type ReferenceDataVerbatimCount = {
  __typename?: 'ReferenceDataVerbatimCount';
  count: Scalars['Float'];
  referenceDataId: Scalars['Float'];
  referenceDataType: Scalars['String'];
};

export type TagsInput = {
  organisationId: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticateUser: Tokens;
  requestPasswordReset: Scalars['Boolean'];
  setPassword: Tokens;
  createUser: User;
  deleteUser: Scalars['Boolean'];
  kickSessions: Scalars['Boolean'];
  createOrganisation: Organisation;
  deleteOrganisation: Scalars['Boolean'];
  createWorkspace: Workspace;
  allocateWorkspaces: Scalars['Boolean'];
  deleteWorkspace: Scalars['Boolean'];
  createVerbatims: CreateVerbatimResult;
  validateVerbatims: ValidateVerbatimResult;
  exportVerbatims: ExportVerbatimResult;
  classifyVerbatim: Verbatim;
  cannotClassifyVerbatim: Verbatim;
  deleteVerbatim: Scalars['Boolean'];
  analyseS3File: AnalyseFileResponse;
  exportTags: ExportTagsResult;
  updateRootCause: RootCause;
  updateReferenceData: Scalars['Boolean'];
  mergeReferenceData: Scalars['Boolean'];
  moveEnquiries: Scalars['Boolean'];
  moveSubEnquiries: Scalars['Boolean'];
};


export type MutationAuthenticateUserArgs = {
  input: AuthenticateUserInput;
};


export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


export type MutationSetPasswordArgs = {
  input: SetPasswordInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationKickSessionsArgs = {
  input: KickSessionsInput;
};


export type MutationCreateOrganisationArgs = {
  input: CreateOrganisationInput;
};


export type MutationDeleteOrganisationArgs = {
  input: DeleteOrganisationInput;
};


export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};


export type MutationAllocateWorkspacesArgs = {
  input: AllocateWorkspacesInput;
};


export type MutationDeleteWorkspaceArgs = {
  input: DeleteWorkspaceInput;
};


export type MutationCreateVerbatimsArgs = {
  input: CreateVerbatimsInput;
};


export type MutationValidateVerbatimsArgs = {
  input: CreateVerbatimsInput;
};


export type MutationExportVerbatimsArgs = {
  input: ExportVerbatimsInput;
};


export type MutationClassifyVerbatimArgs = {
  input: ClassifyVerbatimInput;
};


export type MutationCannotClassifyVerbatimArgs = {
  verbatimId: Scalars['Float'];
};


export type MutationDeleteVerbatimArgs = {
  input: DeleteVerbatimInput;
};


export type MutationAnalyseS3FileArgs = {
  input: AnalyseS3FileInput;
};


export type MutationExportTagsArgs = {
  input: ExportTagsInput;
};


export type MutationUpdateRootCauseArgs = {
  input: UpdateRootCauseInput;
};


export type MutationUpdateReferenceDataArgs = {
  input: UpdateReferenceDataInput;
};


export type MutationMergeReferenceDataArgs = {
  input: MergeReferenceDataInput;
};


export type MutationMoveEnquiriesArgs = {
  input: MoveEnquiriesInput;
};


export type MutationMoveSubEnquiriesArgs = {
  input: MoveSubEnquiriesInput;
};

export type Tokens = {
  __typename?: 'Tokens';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  mfaRequired?: Maybe<Scalars['Boolean']>;
};

export type AuthenticateUserInput = {
  code?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type RequestPasswordResetInput = {
  email: Scalars['String'];
};

export type SetPasswordInput = {
  code: Scalars['String'];
  password: Scalars['String'];
};

export type CreateUserInput = {
  role: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type DeleteUserInput = {
  userId: Scalars['Float'];
};

export type KickSessionsInput = {
  userId: Scalars['Float'];
};

export type CreateOrganisationInput = {
  name: Scalars['String'];
};

export type DeleteOrganisationInput = {
  organisationId: Scalars['Float'];
};

export type CreateWorkspaceInput = {
  name: Scalars['String'];
  type: WorkspaceType;
  organisationId: Scalars['Float'];
  ownerId?: Maybe<Scalars['Float']>;
};

export type AllocateWorkspacesInput = {
  organisationId: Scalars['Float'];
  s3ObjectKey: Scalars['String'];
  allocations: Array<WorkspaceAllocation>;
};

export type WorkspaceAllocation = {
  userId: Scalars['Float'];
  existingWorkspaceId?: Maybe<Scalars['Float']>;
  newWorkspaceName?: Maybe<Scalars['String']>;
  allocatedValue: Scalars['Float'];
};

export type DeleteWorkspaceInput = {
  workspaceId: Scalars['Float'];
};

export type CreateVerbatimResult = {
  __typename?: 'CreateVerbatimResult';
  successCount: Scalars['Float'];
  failureCount: Scalars['Float'];
  failureIds: Array<Scalars['String']>;
};

export type CreateVerbatimsInput = {
  uploadKey: Scalars['String'];
  workspaceId: Scalars['Float'];
  organisationId: Scalars['Float'];
  uploadMode?: Maybe<Scalars['String']>;
};

export type ValidateVerbatimResult = {
  __typename?: 'ValidateVerbatimResult';
  duplicateSourceIdsInSystem: Array<Scalars['String']>;
  duplicateSourceIdsInFile: Array<Scalars['String']>;
};

export type ExportVerbatimResult = {
  __typename?: 'ExportVerbatimResult';
  url: Scalars['String'];
};

export type ExportVerbatimsInput = {
  workspaceId: Scalars['Float'];
  organisationId: Scalars['Float'];
};

export type ClassifyVerbatimInput = {
  verbatimId: Scalars['Float'];
  organisationId: Scalars['Float'];
  rootCauseId?: Maybe<Scalars['Float']>;
  rootCause?: Maybe<Scalars['String']>;
  enquiryId?: Maybe<Scalars['Float']>;
  enquiry?: Maybe<Scalars['String']>;
  subEnquiryId?: Maybe<Scalars['Float']>;
  subEnquiry?: Maybe<Scalars['String']>;
  targetClassCorrect?: Maybe<Scalars['Boolean']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<TagInput>>;
  resolution?: Maybe<Scalars['String']>;
};

export type TagInput = {
  text: Scalars['String'];
  tag: Scalars['String'];
};

export type DeleteVerbatimInput = {
  verbatimId: Scalars['Float'];
};

export type AnalyseFileResponse = {
  __typename?: 'AnalyseFileResponse';
  s3ObjectKey: Scalars['String'];
  verbatims: Array<VerbatimRow>;
  existingVerbatimCount: Scalars['Float'];
  newVerbatimCount: Scalars['Float'];
};

export type VerbatimRow = {
  __typename?: 'VerbatimRow';
  verbatim: Scalars['String'];
  sourceId: Scalars['String'];
  rootCause: Scalars['String'];
  enquiry: Scalars['String'];
  subEnquiry: Scalars['String'];
  targetClassName: Scalars['String'];
  targetClassValue: Scalars['String'];
};

export type AnalyseS3FileInput = {
  s3ObjectKey: Scalars['String'];
  organisationId: Scalars['Float'];
};

export type ExportTagsResult = {
  __typename?: 'ExportTagsResult';
  url: Scalars['String'];
};

export type ExportTagsInput = {
  organisationId: Scalars['Float'];
};

export type UpdateRootCauseInput = {
  rootCauseId: Scalars['Float'];
  rootCause: Scalars['String'];
};

export type UpdateReferenceDataInput = {
  id: Scalars['Float'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export type MergeReferenceDataInput = {
  type: Scalars['String'];
  toId: Scalars['Float'];
  fromIds: Array<Scalars['Float']>;
};

export type MoveEnquiriesInput = {
  toRootCauseId: Scalars['Float'];
  enquiryIds: Array<Scalars['Float']>;
};

export type MoveSubEnquiriesInput = {
  toEnquiryId: Scalars['Float'];
  subEnquiryIds: Array<Scalars['Float']>;
};
