import React from "react";

import gql from "graphql-tag";
import { Modal2 } from "sbui";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { GET_ROOT_CAUSES } from "../Classify";
import styled from "styled-components";
import { GET_REFERENCE_DATA_VERBATIM_COUNT } from ".";
import MoveSubEnquiriesForm from "./MoveSubEnquiriesForm";

const MOVE_SUB_ENQUIRIES = gql`
  mutation moveSubEnquiries($input: MoveSubEnquiriesInput!) {
    moveSubEnquiries(input: $input)
  }
`;

type Inputs = {
  enquiry: any;
};

const HelperText = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;
`;

const WarningText = styled.div`
  ul {
    list-style: none;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const MoveSubEnquiriesModal = ({
  isOpen,
  referenceData,
  rootCauses,
  organisationId,
  onRequestClose,
}: any) => {
  let valueMap: any = {};

  rootCauses.forEach((rootCause: any) => {
    valueMap[`rootCause:${rootCause.id}`] = rootCause.value;
    rootCause.enquiries.forEach((enquiry: any) => {
      valueMap[`enquiry:${enquiry.id}`] = enquiry.value;
      enquiry.subEnquiries.forEach((subEnquiry: any) => {
        valueMap[`subEnquiry:${subEnquiry.id}`] = subEnquiry.value;
      });
    });
  });

  const { handleSubmit, ...form } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    moveSubEnquiries({
      variables: {
        input: {
          toEnquiryId: data.enquiry.value,
          subEnquiryIds: referenceData.map((r: any) => parseInt(r.id)),
        },
      },
    });
  };

  const [moveSubEnquiries, { loading }] = useMutation(MOVE_SUB_ENQUIRIES, {
    onCompleted: (res) => {
      onRequestClose(true);
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ROOT_CAUSES,
        variables: {
          input: {
            organisationId,
          },
        },
      },
      {
        query: GET_REFERENCE_DATA_VERBATIM_COUNT,
        variables: {
          organisationId: organisationId,
        },
      },
    ],
  });

  return (
    <Modal2
      titleText="referenceData.merge.label"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
    >
      <HelperText>
        Select an enquiry to move the selected enquiries into:{" "}
      </HelperText>
      <MoveSubEnquiriesForm
        onSubmit={onRequestClose}
        form={form}
        rootCauses={rootCauses}
        referenceData={referenceData}
      />
    </Modal2>
  );
};

export default withRouter(MoveSubEnquiriesModal);
