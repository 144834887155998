import React, { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Card } from "sbui";
import Loading from "sbui/components/loading";
import AccessLogsTable from "./AccessLogsTable";
import { PaginationContainer } from "components/PaginationContainer";
import { fetchMoreHelper } from "utils/pagination";
import { useBreadcrumbsDispatch } from "components/Breadcrumbs";

export const GET_ACCESS_LOGS = gql`
  query accessLogs($input: PageDetailsInput!) {
    accessLogs(input: $input) {
      items {
        userId
        action
        userEmail
        ipAddress
        context
        createdAt
      }
      total
      hasMore
    }
  }
`;

const PAGE_SIZE = 10;

const AccessLogs = () => {
  const { loading, data, fetchMore } = useQuery(GET_ACCESS_LOGS, {
    variables: {
      input: {
        limit: PAGE_SIZE,
        offset: 0,
      },
    },
  });

  const dispatch = useBreadcrumbsDispatch();
  useEffect(() => {
    dispatch({
      type: "setBreadcrumbs",
      value: [
        {
          label: "accessLogs.heading",
        },
      ],
    });
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Card heading="accessLogs.heading">
      <PaginationContainer
        hasMore={data?.accessLogs?.hasMore}
        onLoadMore={(done: any) => {
          fetchMoreHelper(fetchMore, "accessLogs", done, {
            input: {
              offset: data.accessLogs.items.length,
              limit: PAGE_SIZE,
            },
          });
        }}
      >
        <AccessLogsTable data={data?.accessLogs?.items || []} />
      </PaginationContainer>
    </Card>
  );
};

export default AccessLogs;
