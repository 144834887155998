import React, { useMemo } from "react";
import { Table, SingleColumnTable, Button } from "sbui";
import { Link, withRouter } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useIntl } from "react-intl";
import styled from "styled-components";
import FromNowCell from "components/FromNowCell";

const Actions = styled.div`
  display: flex;
  button {
    margin-right: 10px;
  }
`;

const getColumns = ({ intl, deleteHandler }: any) => [
  {
    Header: "name.label",
    accessor: "name",
    width: 20,
    Cell: ({ row }: any) => {
      return (
        <Link to={`/organisations/${row.original.id}`}>
          {row.original.name}
        </Link>
      );
    },
  },
  {
    Header: "verbatimCount.label",
    accessor: "verbatimCount",
    width: 20,
  },
  {
    Header: "updatedAt.label",
    accessor: "updatedAt",
    width: 20,
    Cell: ({ row }: any) => {
      return <FromNowCell date={row.original.updatedAt} />;
    },
  },
  {
    Header: "createdAt.label",
    accessor: "createdAt",
    width: 20,
    Cell: ({ row }: any) => {
      return <FromNowCell date={row.original.createdAt} />;
    },
  },
  {
    Header: "actions.label",
    accessor: "actions",
    width: 30,
    Cell: ({ row }: any) => {
      return (
        <Actions>
          <Button
            label="delete.label"
            onClick={() => {
              deleteHandler(row.original);
            }}
          />
        </Actions>
      );
    },
  },
];

const OrganisationsTable = ({ data, deleteHandler }: any) => {
  const intl = useIntl();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const tableColumns = useMemo(
    () =>
      getColumns({
        intl,
        deleteHandler,
      }),
    [intl, deleteHandler]
  );

  const TableComponent = isMobile ? SingleColumnTable : Table;

  return <TableComponent columns={tableColumns} data={data} />;
};

export default withRouter(OrganisationsTable);
