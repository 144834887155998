import React from "react";

import gql from "graphql-tag";
import { Modal2, RegularText } from "sbui";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useQuery } from "@apollo/client";
import { SelectField } from "components/FormFields/SelectField";

export const GET_TAGS = gql`
  query tags($input: TagsInput) {
    tags(input: $input) {
      id
      tag
      createdAt
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 30vw;
`;

const SelectedText = styled(RegularText)`
  margin-bottom: 20px;
`;

const StyledSelectField = styled(SelectField)`
  margin-top: 20px;
`;

type Inputs = {
  tag: { label: string; value: string };
};

const NewTagModal = ({
  isOpen,
  addTagHandler,
  highlightedText,
  onRequestClose,
}: any) => {
  const { loading, data } = useQuery(GET_TAGS);

  const { handleSubmit, ...form } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    addTagHandler(data.tag.value);
    onRequestClose();
  };

  const tagOptions = (data?.tags || []).map((tag: any) => {
    return {
      value: tag.tag,
      label: tag.tag,
    };
  });

  return (
    <Modal2
      titleText="newTag.label"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
    >
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectedText>{`Selected Text: ${highlightedText}`}</SelectedText>
          <StyledSelectField
            name="tag"
            form={form as any}
            options={tagOptions}
            creatable={true}
            required={false}
          />
        </form>
      </Container>
    </Modal2>
  );
};

export default NewTagModal;
