import { Modal } from "sbui";
import { withRouter } from "react-router-dom";
import AllocateWorkspacesForm from "./AllocateWorkspacesForm";
import { useForm } from "react-hook-form";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const ALLOCATE_WORKSPACES = gql`
  mutation allocateWorkspaces($input: AllocateWorkspacesInput!) {
    allocateWorkspaces(input: $input)
  }
`;

const AllocateWorkspacesModal = ({
  isOpen,
  organisationId,
  onRequestClose,
  maxAllocationTotal,
  s3ObjectKey,
}: any) => {
  const validationSchema = yup.object().shape({
    allocations: yup
      .array()
      .of(
        yup.object().shape({
          user: yup.mixed().required("User is required"),
          workspace: yup
            .object()
            .shape({
              label: yup.string().required("Label is required"),
              value: yup.mixed().required("Value is required"),
            })
            .required("Workspace is required"),
          allocated: yup
            .number()
            .required("Allocated Count is required")
            .min(0, "Allocated Count must be at least 0"),
        })
      )
      .test(
        "sum-of-allocated",
        `Sum of allocated values cannot exceed ${maxAllocationTotal}`,
        (allocations) => {
          const sum =
            allocations?.reduce(
              (acc, curr) => acc + Number(curr.allocated),
              0
            ) || 0;

          return sum <= maxAllocationTotal;
        }
      ),
  });
  const form = useForm({
    defaultValues: {},
    resolver: yupResolver(validationSchema),
  });

  const [allocateWorkspaces, { loading }] = useMutation(ALLOCATE_WORKSPACES, {
    onCompleted: (res) => {
      onRequestClose();
    },
  });

  const onSubmit = (data: any) => {
    allocateWorkspaces({
      variables: {
        input: {
          organisationId: organisationId,
          allocations: data.allocations.map((allocation: any) => ({
            userId: allocation.user.value,
            newWorkspaceName:
              typeof allocation.workspace.value === "string"
                ? allocation.workspace.value
                : undefined,
            existingWorkspaceId: allocation.workspace?.value?.id || undefined,
            allocatedValue: parseInt(allocation.allocated),
          })),
          s3ObjectKey: s3ObjectKey,
        },
      },
    });
  };

  return (
    <Modal
      titleText="workspaces.allocate.label"
      isOpen={isOpen}
      loading={loading}
      onRequestClose={onRequestClose}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <AllocateWorkspacesForm
        form={form}
        maxAllocationTotal={maxAllocationTotal}
        organisationId={organisationId}
      />
    </Modal>
  );
};

export default withRouter(AllocateWorkspacesModal);
