import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "sbui";

const Container = styled.div``;
const ButtonContainer = styled.div`
  margin: 10px;
`;
const ChildrenContainer = styled.div``;
type PaginationContainerProps = {
  hasMore: boolean;
  onLoadMore: any;
  children: any;
};

export const PaginationContainer = ({
  children,
  hasMore,
  onLoadMore,
}: PaginationContainerProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <Container>
      <ChildrenContainer>{children}</ChildrenContainer>
      <ButtonContainer>
        {hasMore && (
          <Button
            loading={loading}
            label="common.loadmore.label"
            onClick={() => {
              setLoading(true);
              onLoadMore(() => {
                setLoading(false);
              });
            }}
          />
        )}
      </ButtonContainer>
    </Container>
  );
};
