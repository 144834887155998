import React from "react";
import { Button } from "sbui";

const SubmitButton = ({ loading, label, className }: any) => {
  return (
    <Button
      primary
      className={className}
      loading={loading}
      type="submit"
      label={label || "submit.label"}
    />
  );
};

export default SubmitButton;
