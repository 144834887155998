import React from "react";

import gql from "graphql-tag";
import { Modal2 } from "sbui";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { GET_ROOT_CAUSES } from "../Classify";
import styled from "styled-components";
import MergeReferenceDataForm from "./MergeReferenceDataForm";
import { useIntl } from "react-intl";
import { GET_REFERENCE_DATA_VERBATIM_COUNT } from ".";

const MERGE_REFERENCE_DATA = gql`
  mutation mergeReferenceData($input: MergeReferenceDataInput!) {
    mergeReferenceData(input: $input)
  }
`;

type Inputs = {
  referenceData: any;
};

const HelperText = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;
`;

const HelperList = styled.ul`
  padding-left: 20px;
`;

const MergeReferenceDataModal = ({
  isOpen,
  type,
  referenceData,
  rootCauses,
  organisationId,
  onRequestClose,
}: any) => {
  const intl = useIntl();
  const { handleSubmit, ...form } = useForm<Inputs>();

  const typeLabel = intl.formatMessage({ id: `${type}.label` });
  let valueMap: any = {};

  rootCauses.forEach((rootCause: any) => {
    valueMap[`rootCause:${rootCause.id}`] = rootCause.value;
    rootCause.enquiries.forEach((enquiry: any) => {
      valueMap[`enquiry:${enquiry.id}`] = enquiry.value;
      enquiry.subEnquiries.forEach((subEnquiry: any) => {
        valueMap[`subEnquiry:${subEnquiry.id}`] = subEnquiry.value;
      });
    });
  });

  const onSubmit = (data: Inputs) => {
    const otherReferenceDataIds = referenceData
      .filter((row: any) => `${row.id}` !== `${data.referenceData}`)
      .map((row: any) => parseInt(row.id));

    mergeReferenceData({
      variables: {
        input: {
          type,
          toId: parseInt(data.referenceData),
          fromIds: otherReferenceDataIds,
        },
      },
    });
  };

  const [mergeReferenceData, { loading }] = useMutation(MERGE_REFERENCE_DATA, {
    onCompleted: (res) => {
      onRequestClose(true);
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ROOT_CAUSES,
        variables: {
          input: {
            organisationId,
          },
        },
      },
      {
        query: GET_REFERENCE_DATA_VERBATIM_COUNT,
        variables: {
          organisationId: organisationId,
        },
      },
    ],
  });

  return (
    <Modal2
      titleText="referenceData.merge.label"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
    >
      <HelperText>
        This process will do the following:{" "}
        <HelperList>
          <li>Delete the 'other' {typeLabel}</li>
          <li>Move all of the enquiries from the 'other' {typeLabel} across</li>
          <li>Update all of the verbatims</li>
        </HelperList>
      </HelperText>
      <MergeReferenceDataForm
        onSubmit={onRequestClose}
        form={form}
        referenceData={referenceData}
        valueMap={valueMap}
        type={type}
        typeLabel={typeLabel}
      />
    </Modal2>
  );
};

export default withRouter(MergeReferenceDataModal);
