import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  button {
    margin-right: ${(props) => props.theme.spacing.large};
  }
`;

const ButtonContainer = ({ children }: any) => {
  return <Container>{children}</Container>;
};

export default ButtonContainer;
