import React from "react";
import FormTextField from "components/FormFields/FormTextField";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 33vw;
  padding-bottom: 10px;
`;

const OrganisationForm = ({ form }: any) => {
  return (
    <Container>
      <FormTextField name="name" form={form} />
    </Container>
  );
};

export default OrganisationForm;
