export const createStore = (init: any) => {
  let store: any = null;
  const get = () => store;
  const set = (operation: any) => (store = operation(store));

  // Beautiful. Our store now has a way to get
  // and set itself when we initialize it
  store = init(get, set);

  const useStore = () => {
    return store;
  };
  return useStore;
};

export const useGlobalStore = createStore((get: any, set: any) => {
  return {
    user: null,
    setUser: (user: any) => set((store: any) => ({ ...store, user })),
  };
});
