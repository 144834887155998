import React, { useMemo } from "react";
import { useTable, useFlexLayout } from "react-table";
import styled from "styled-components";
import TableStyles from "./TableStyles";
import { RegularText } from "../..";
import { useIntl } from "react-intl";

const FieldContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.xlarge};
`;

const FieldHeader = styled.h5`
  color: ${(props) => props.theme.table.singleColumn.colors.fieldHeader};
  margin-bottom: 8px;
`;

const FieldText = styled(RegularText)``;

const SingleColumnTable = ({ columns, data, header }: any) => {
  const singleColumn = useMemo(
    () => [
      {
        id: "singleColumn",
        accessor: "singleColumn",
        Cell: ({ row }: any) => {
          const intl = useIntl();
          return columns.map((column: any) => {
            const accessor = column.accessor;
            // if (accessor === "actions") {
            //   return null;
            // }

            const fieldHeading = column["Header"];
            const headerIntl = intl.formatMessage({ id: fieldHeading });

            const fieldData = column.Cell
              ? column.Cell({ row })
              : row.original[accessor];

            return (
              <FieldContainer>
                <FieldHeader>{headerIntl}</FieldHeader>
                <FieldText>{fieldData}</FieldText>
              </FieldContainer>
            );
          });
        },
      },
    ],
    [columns]
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable(
    {
      columns: singleColumn,
      data,
    },
    useFlexLayout
  );

  return (
    <table {...getTableProps()}>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const StyledTable = ({ columns, data }: any) => {
  return (
    <TableStyles isSingleColumn={true}>
      <SingleColumnTable columns={columns} data={data} />
    </TableStyles>
  );
};

export default StyledTable;
