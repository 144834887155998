import React from "react";
import Icon from "@material-ui/core/Icon";
import styled from "styled-components";

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

type IconButtonProps = {
  className?: string;
  name?: string;
  onClick?: any;
};

const IconButton = React.forwardRef<any, IconButtonProps>(
  ({ name, className, ...props }, ref) => {
    return (
      <StyledIcon className={className} ref={ref} {...props}>
        {name}
      </StyledIcon>
    );
  }
);

export default IconButton;
