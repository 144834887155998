import { WorkspaceType } from "types";

export const WORKSPACE_OPTIONS = [
  {
    value: WorkspaceType.Live,
    label: 'workspace.type.' + WorkspaceType.Live
  },
  {
    value: WorkspaceType.Experimental,
    label: 'workspace.type.' + WorkspaceType.Experimental
  }
];