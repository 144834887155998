import React from "react";

import gql from "graphql-tag";
import { Modal2, RegularText } from "sbui";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { GET_ORGANISATION, GET_WORKSPACES } from "./index";

export const DELETE_WORKSPACE = gql`
  mutation deleteWorkspace($input: DeleteWorkspaceInput!) {
    deleteWorkspace(input: $input)
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextContainer = styled.div`
  margin-top: 10px;
  bottom-top: 10px;
`;

const DeleteWorkspaceModal = ({ isOpen, workspace, onRequestClose }: any) => {
  const [deleteWorkspace, { loading }] = useMutation(DELETE_WORKSPACE, {
    onCompleted: () => {
      onRequestClose();
    },
    refetchQueries: [
      {
        query: GET_ORGANISATION,
        variables: {
          organisationId: workspace.organisationId,
        },
      },
      {
        query: GET_WORKSPACES,
        variables: {
          organisationId: workspace.organisationId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <Modal2
      titleText="deleteWorkspace.label"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={() => {
        deleteWorkspace({
          variables: {
            input: {
              workspaceId: workspace.id,
            },
          },
        });
      }}
      loading={loading}
    >
      <Container>
        {`Name: ${workspace.name}`}
        <TextContainer>
          <RegularText label="Are you sure you want to delete this workspace and all of its child data?" />
        </TextContainer>
      </Container>
    </Modal2>
  );
};

export default DeleteWorkspaceModal;
