import React from "react";
import styled from "styled-components";
import { SelectField } from "components/FormFields/SelectField";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 33vw;
  padding-bottom: 10px;
`;

const MoveEnquiriesForm = ({ form, rootCauses, referenceData }: any) => {
  const rootCauseOptions = rootCauses
    .filter((rc: any) => {
      return (
        rc.enquiries.find(
          (e: any) => `${e.id}` === `${referenceData[0].id}`
        ) === undefined
      );
    })
    .map((rootCause: any) => {
      return {
        label: rootCause.value,
        value: rootCause,
      };
    });

  return (
    <Container>
      <SelectField name="rootCause" form={form} options={rootCauseOptions} />
    </Container>
  );
};

export default MoveEnquiriesForm;
