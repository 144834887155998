import React, { useMemo } from "react";
import { Table, SingleColumnTable } from "sbui";
import { withRouter } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useIntl } from "react-intl";
import FromNowCell from "components/FromNowCell";

const getColumns = ({ intl }: any) => [
  {
    Header: "tag.label",
    accessor: "tag",
  },
  {
    Header: "createdAt.label",
    accessor: "createdAt",

    Cell: ({ row }: any) => {
      return <FromNowCell date={row.original.createdAt} />;
    },
  },
];

const TagsTable = ({ data }: any) => {
  const intl = useIntl();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const tableColumns = useMemo(
    () =>
      getColumns({
        intl,
      }),
    [intl]
  );

  const TableComponent = isMobile ? SingleColumnTable : Table;

  return <TableComponent columns={tableColumns} data={data} />;
};

export default withRouter(TagsTable);
