import React from "react";
import styled from "styled-components";
import { IconButton, Dropdown } from "sbui";
import { User } from "types";

const AvatarMenuButton = styled.div`
  cursor: pointer;
  display: flex;
`;

type AvatarMenuProps = {
  onSelect?: any;
  items?: any;
  user: User;
};

const DropdownMenu = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarMenu = ({ items, user, onSelect }: AvatarMenuProps) => {
  return (
    <AvatarMenuButton>
      <Dropdown items={items} onSelect={onSelect}>
        {({ isOpen }: any) => (
          <DropdownMenu data-test="avatar-menu">
            {user?.firstName}
            <IconButton name={isOpen ? "arrow_drop_up" : "arrow_drop_down"} />
          </DropdownMenu>
        )}
      </Dropdown>
    </AvatarMenuButton>
  );
};

export default AvatarMenu;
