import { useMemo } from "react";
import { Table, SingleColumnTable } from "sbui";
import { withRouter } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

const Styles = styled.div``;

const VerbatimCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledReactTooltip = styled(ReactTooltip)`
  width: 300px;
`;

const getColumns = () => [
  {
    Header: "sourceId.label",
    accessor: "sourceId",
    width: 80,
    Cell: ({ row }: any) => {
      return row.original.sourceId;
    },
  },
  {
    Header: "verbatim.label",
    accessor: "verbatim",
    Cell: ({ row }: any) => {
      return (
        <VerbatimCell>{row.original.verbatim.substr(0, 100)}</VerbatimCell>
      );
    },
  },
];

const AnalyseVerbatimsTable = ({ data }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const tableColumns = useMemo(() => getColumns(), []);

  const TableComponent = isMobile ? SingleColumnTable : Table;

  return (
    <Styles>
      <StyledReactTooltip id="verbatimTable" />
      <TableComponent columns={tableColumns} data={data} />
    </Styles>
  );
};

export default withRouter(AnalyseVerbatimsTable);
