import { renderTag } from "sbui/utils/tags";
import { ClassifyVerbatimInput, Tag, Verbatim } from "types";
import { ClassifyInputs } from ".";

export const verbatimChanged = (verbatim: Verbatim, data: ClassifyInputs) => {
  if (verbatim.rootCause?.value !== data.rootCause?.label) {
    return true;
  }

  if (verbatim.enquiry?.value !== data.enquiry?.label) {
    return true;
  }

  if (verbatim.subEnquiry?.value !== data.subEnquiry?.label) {
    return true;
  }

  const leftTags = (verbatim.tags || [])
    .map((tag) => `${tag.text}:${tag.tag}`)
    .sort();
  const rightTags = data.tags
    .map((tag) => `${tag.value.text}:${tag.value.tag}`)
    .sort();

  if (JSON.stringify(leftTags) !== JSON.stringify(rightTags)) {
    return true;
  }

  if (
    JSON.stringify((verbatim.keywords || []).sort()) !==
    JSON.stringify((data.keywords || []).sort())
  ) {
    return true;
  }

  if (verbatim.targetClassCorrect !== data.targetClassCorrect) {
    return true;
  }

  return false;
};

export const setInitialFormState = (
  form: any,
  verbatim: Verbatim,
  tagsData: any,
  rootCausesData: any
) => {
  if (!verbatim) {
    return;
  }

  const value: any = {
    targetClassCorrect: null,
    tags: [],
    keywords: [],
    rootCause: null,
    enquiry: null,
    subEnquiry: null,
    resolution: null,
  };

  form.reset(value);

  if (verbatim.resolution) {
    value["resolution"] = {
      value: verbatim.resolution,
      label: verbatim.resolution,
    };
  }

  // Target Class
  if (verbatim.targetClassName && verbatim.targetClassCorrect !== null) {
    value["targetClassCorrect"] = verbatim.targetClassCorrect ? "Yes" : "No";
  }

  // Tags
  if (tagsData) {
    const initialTags = (verbatim.tags || []).map((verbatimTag: any) => {
      const existingTag = tagsData.tags.find(
        (tag: Tag) => tag.id === verbatimTag.tag.id
      );

      return {
        __isNew__: false,
        label: renderTag(verbatimTag.text, existingTag.tag),
        value: {
          text: verbatimTag.text,
          tag: existingTag.tag,
        },
      };
    });

    value["tags"] = initialTags;
  }

  // Keywords
  const initialKeywords = (verbatim.keywords || []).map((keyword: any) => {
    return {
      label: keyword,
      value: keyword,
    };
  });
  value["keywords"] = initialKeywords;

  // Root Cause / Enquiry / Sub Enquiry
  const initialRootCause = rootCausesData?.rootCauses.find((rootCause: any) => {
    return rootCause.id === verbatim?.rootCause?.id;
  });

  if (initialRootCause) {
    value["rootCause"] = {
      value: initialRootCause.id,
      label: initialRootCause.value,
    };

    const initialEnquiry = initialRootCause.enquiries.find((enquiry: any) => {
      return enquiry.id === verbatim?.enquiry?.id;
    });

    if (initialEnquiry) {
      const initialSubEnquiry = initialEnquiry.subEnquiries.find(
        (subEnquiry: any) => {
          return subEnquiry.id === verbatim?.subEnquiry?.id;
        }
      );

      if (initialSubEnquiry) {
        value["subEnquiry"] = {
          value: initialSubEnquiry.id,
          label: initialSubEnquiry.value,
        };
      }

      value["enquiry"] = {
        value: initialEnquiry.id,
        label: initialEnquiry.value,
      };
    }
  }

  form.reset(value);
};

export const buildCreateVerbatimInputFromForm = (
  verbatim: Verbatim,
  organisationId: number,
  formData: any
) => {
  const verbatimData: ClassifyVerbatimInput = {
    verbatimId: verbatim.id,
    organisationId: organisationId,
  };

  if (formData.rootCause?.__isNew__) {
    verbatimData.rootCause = formData.rootCause.value;
  } else if (formData.rootCause?.value) {
    verbatimData.rootCauseId = parseInt(formData.rootCause.value);
  } else {
    verbatimData.rootCauseId = null;
  }

  if (formData.enquiry?.__isNew__) {
    verbatimData.enquiry = formData.enquiry.value;
  } else if (formData.enquiry?.value) {
    verbatimData.enquiryId = parseInt(formData.enquiry.value);
  } else {
    verbatimData.enquiryId = null;
  }

  if (formData.subEnquiry?.__isNew__) {
    verbatimData.subEnquiry = formData.subEnquiry.value;
  } else if (formData.subEnquiry?.value) {
    verbatimData.subEnquiryId = parseInt(formData.subEnquiry.value);
  } else {
    verbatimData.subEnquiryId = null;
  }

  if (formData.tags) {
    verbatimData.tags = formData.tags.map((tag: any) => {
      return {
        text: tag.value.text,
        tag: tag.value.tag,
      };
    });
  } else {
    verbatimData.tags = null;
  }

  if (formData.keywords) {
    verbatimData.keywords = formData.keywords.map((keyword: any) => {
      return keyword.value;
    });
  } else {
    verbatimData.keywords = null;
  }

  if (formData.targetClassCorrect) {
    verbatimData.targetClassCorrect = formData.targetClassCorrect === "Yes";
  } else {
    verbatimData.targetClassCorrect = null;
  }

  if (formData.resolution) {
    verbatimData.resolution = formData.resolution.value;
  } else {
    verbatimData.resolution = null;
  }

  return verbatimData;
};
