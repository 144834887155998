import { logout } from "../utils/auth";

const Logout = () => {
  logout();

  window.location.href = "/";

  return null;
};

export default Logout;
