import React, { useMemo } from "react";
import { Table, SingleColumnTable, Button } from "sbui";
import { withRouter } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useIntl } from "react-intl";
import styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check";
import ReactTooltip from "react-tooltip";
import { Link } from "@material-ui/core";

const Styles = styled.div``;

const VerbatimCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledReactTooltip = styled(ReactTooltip)`
  width: 300px;
`;

const Actions = styled.div`
  display: flex;
  button {
    margin-right: 10px;
  }
`;

const ValueSelectedCell = ({ column, row }: any) => {
  let value = row.original[column.id];
  if (Array.isArray(value)) {
    value = value.length > 0 ? true : false;
  }

  return <div>{value ? <CheckIcon /> : "-"}</div>;
};

const getColumns = ({
  intl,
  deleteHandler,
  organisationId,
  workspaceId,
}: any) => [
  {
    Header: "sourceId.label",
    accessor: "sourceId",
    width: 15,
    Cell: ({ row }: any) => {
      return (
        <Link
          href={`/organisations/${organisationId}/workspaces/${workspaceId}/classify/${row.original.id}`}
        >
          {row.original.sourceId}
        </Link>
      );
    },
  },
  {
    Header: "status.label",
    accessor: "status",
    width: 5,
    Cell: ({ row }: any) => {
      return intl.formatMessage({
        id: `verbatim.status.${row.original.status}`,
      });
    },
  },
  {
    Header: "verbatim.label",
    accessor: "verbatim",
    width: 15,
    Cell: ({ row }: any) => {
      return <VerbatimCell>{row.original.verbatim.substr(0, 50)}</VerbatimCell>;
    },
  },
  {
    Header: "rootCause.label",
    accessor: "rootCause.value",
    width: 10,
  },
  {
    Header: "enquiry.label",
    accessor: "enquiry.value",
    width: 10,
  },
  {
    Header: "subEnquiry.label",
    accessor: "subEnquiry",
    width: 7,
    Cell: ValueSelectedCell,
  },
  {
    Header: "tags.label",
    accessor: "tags",
    width: 7,
    Cell: ValueSelectedCell,
  },
  {
    Header: "keywords.label",
    accessor: "keywords",
    width: 7,
    Cell: ValueSelectedCell,
  },
  {
    Header: "actions.label",
    accessor: "actions",
    width: 10,
    Cell: ({ row }: any) => {
      return (
        <Actions>
          <Button
            label="delete.label"
            onClick={() => {
              deleteHandler(row.original);
            }}
          />
        </Actions>
      );
    },
  },
];

const VerbatimsTable = ({
  data,
  deleteHandler,
  organisationId,
  workspaceId,
}: any) => {
  const intl = useIntl();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const tableColumns = useMemo(
    () =>
      getColumns({
        intl,
        deleteHandler,
        organisationId,
        workspaceId,
      }),
    [intl, deleteHandler, organisationId, workspaceId]
  );

  const TableComponent = isMobile ? SingleColumnTable : Table;

  return (
    <Styles>
      <StyledReactTooltip id="verbatimTable" />
      <TableComponent columns={tableColumns} data={data} />
    </Styles>
  );
};

export default withRouter(VerbatimsTable);
