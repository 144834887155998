import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useBreadcrumbsDispatch } from "components/Breadcrumbs";
import ProgressBar from "components/ProgressBar";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";
import { Card, ContentContainer, Button, CardButton, CardActions } from "sbui";
import Loading from "sbui/components/loading";
import styled from "styled-components";
import { VerbatimStatus, Verbatim } from "types";
import ClassificationForm from "./ClassificationForm";
import { GET_TAGS } from "../Classify/NewTagModal";
import { GET_ORGANISATION } from "../Organisation";
import { GET_VERBATIMS } from "../Workspace";
import {
  buildCreateVerbatimInputFromForm,
  setInitialFormState,
} from "../Classify/helpers";
import { useIntl } from "react-intl";

export const GET_WORKSPACE = gql`
  query workspace($workspaceId: Float!) {
    workspace(workspaceId: $workspaceId) {
      id
      name
    }
  }
`;

export const GET_ROOT_CAUSES = gql`
  query rootCauses($input: GetRootCausesInput!) {
    rootCauses(input: $input) {
      id
      value
      organisationId
      enquiries {
        id
        value
        subEnquiries {
          id
          value
        }
      }
    }
  }
`;

export const CLASSIFY_VERBATIM = gql`
  mutation classifyVerbatim($input: ClassifyVerbatimInput!) {
    classifyVerbatim(input: $input) {
      id
      sourceId
      verbatim
      status
      keywords
      targetClassValue
      targetClassName
      targetClassCorrect
      resolution
      tags {
        tagId
        tag {
          id
          tag
        }
        text
      }
      rootCause {
        id
        value
      }
      enquiry {
        id
        value
      }
      subEnquiry {
        id
        value
      }
    }
  }
`;

export const CANNOT_CLASSIFY_VERBATIM = gql`
  mutation cannotClassifyVerbatim($verbatimId: Float!) {
    cannotClassifyVerbatim(verbatimId: $verbatimId) {
      id
      sourceId
      verbatim
      status
      keywords
      targetClassValue
      targetClassName
      targetClassCorrect
      resolution
      tags {
        tagId
        tag {
          id
          tag
        }
        text
      }
      rootCause {
        id
        value
      }
      enquiry {
        id
        value
      }
      subEnquiry {
        id
        value
      }
    }
  }
`;

type VerbatimTag = {
  text: string;
  tag: string;
};

type CreatableSelectOption = {
  label: string;
  value: string;
  __isNew__?: boolean;
};

type TagSelectOption = {
  label: string;
  value: VerbatimTag;
  __isNew__: boolean;
};

export type ClassifyInputs = {
  status: VerbatimStatus | "";
  rootCause: CreatableSelectOption | null;
  enquiry: CreatableSelectOption | null;
  subEnquiry: CreatableSelectOption | null;
  tags: TagSelectOption[];
  keywords: CreatableSelectOption[];
  targetClassCorrect: string | null;
  resolution: string | null;
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const VerbatimContainer = styled(Card)`
  flex: 0.6;
  overflow-y: scroll;
  max-height: 90vh;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
const SidebarContainer = styled(Card)`
  flex: 0.4;
`;

const VerbatimText = styled.div`
  margin: 10px;
`;

const Actions = styled.div`
  display: flex;
  margin: 10px;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ProgressBarContainer = styled.div`
  margin: 10px;
`;

const FilterButton = styled(CardButton)`
  font-size: 10px;
`;

const ActionsContainer = styled(ContentContainer)`
  padding-top: 10px;
`;

const Classify = ({ match }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeFilter, setActiveFilter] = useState("");
  const intl = useIntl();

  const interactionId = match.params.interactionId;

  const { loading: orgLoading, data: orgData } = useQuery(GET_ORGANISATION, {
    variables: {
      organisationId: parseInt(match.params.organisationId),
    },
  });

  const { loading: tagLoading, data: tagsData } = useQuery(GET_TAGS);

  const { loading: workspaceLoading, data: workspaceData } = useQuery(
    GET_WORKSPACE,
    {
      variables: {
        workspaceId: parseInt(match.params.id),
      },
    }
  );

  const {
    loading: rootCausesLoading,
    data: rootCausesData,
    refetch: refetchRootCauses,
  } = useQuery(GET_ROOT_CAUSES, {
    variables: {
      input: {
        organisationId: parseInt(match.params.organisationId),
      },
    },
  });

  const [getVerbatims, { loading, data }] = useLazyQuery(GET_VERBATIMS);

  const [classifyVerbatim, { loading: classifyVerbatimLoading }] =
    useMutation(CLASSIFY_VERBATIM);

  const [cannotClassifyVerbatim, { loading: cannotClassifyVerbatimLoading }] =
    useMutation(CANNOT_CLASSIFY_VERBATIM);

  const { handleSubmit: handleClassifySubmit, ...classifyForm } =
    useForm<ClassifyInputs>({
      defaultValues: {
        rootCause: null,
        enquiry: null,
        subEnquiry: null,
        tags: [],
        keywords: [],
        targetClassCorrect: null,
        resolution: null,
      },
    });

  const currentVerbatim: Verbatim = data?.verbatims?.items[currentIndex];
  const total = data?.verbatims?.total;
  const anyLoading =
    loading || classifyVerbatimLoading || cannotClassifyVerbatimLoading;

  // Handle any filter changes
  useEffect(() => {
    const input: any = {
      workspaceId: parseInt(match.params.id),
    };

    if (activeFilter !== "") {
      input.status = activeFilter;
    }

    if (interactionId) {
      input.interactionId = parseInt(interactionId);
    }

    getVerbatims({
      variables: {
        input: input,
      },
    });
  }, [getVerbatims, activeFilter, match.params.id]);

  // Set the form state form the current verbatim
  useEffect(() => {
    if (currentVerbatim && tagsData && rootCausesData) {
      setInitialFormState(
        classifyForm,
        currentVerbatim,
        tagsData,
        rootCausesData
      );
    }
  }, [currentVerbatim, tagsData, rootCausesData]);

  const onClassifySubmit = async (data: ClassifyInputs) => {
    await classifyVerbatim({
      variables: {
        input: buildCreateVerbatimInputFromForm(
          currentVerbatim,
          parseInt(match.params.organisationId),
          data
        ),
      },
    });
    refetchRootCauses && (await refetchRootCauses());
  };

  const skipToNew = () => {
    if (data?.verbatims?.items) {
      const index = data.verbatims.items.findIndex((item: any) => {
        return item.status === VerbatimStatus.New;
      });
      if (index >= 0) {
        setCurrentIndex(index);
      }
    }
  };

  const next = async () => {
    await handleClassifySubmit(onClassifySubmit)();
    safeMove(1);
  };

  const save = async () => {
    await handleClassifySubmit(onClassifySubmit)();
  };

  const previous = async () => {
    await handleClassifySubmit(onClassifySubmit)();
    safeMove(-1);
  };

  const safeMove = (mod: number) => {
    const newIndex = currentIndex + mod;

    if (newIndex >= 0 && newIndex <= total) {
      setCurrentIndex(newIndex);
    }
  };

  const cannotClassify = async () => {
    await cannotClassifyVerbatim({
      variables: {
        verbatimId: currentVerbatim.id,
      },
    });

    if (!lastItem) {
      safeMove(1);
    }
  };

  const changeFilter = (filter: string) => {
    setCurrentIndex(0);
    setActiveFilter(filter);
  };

  const dispatch = useBreadcrumbsDispatch();
  useEffect(() => {
    if (orgData && workspaceData) {
      dispatch({
        type: "setBreadcrumbs",
        value: [
          {
            label: "organisations.heading",
            to: "/organisations",
          },
          {
            label: orgData.organisation.name,
            to: `/organisations/${orgData.organisation.id}`,
          },
          {
            label: workspaceData.workspace.name,
            to: `/organisations/${orgData.organisation.id}/workspaces/${workspaceData.workspace.id}`,
          },
          {
            label: "classification.label",
          },
        ],
      });
    }
  }, [dispatch, workspaceData, orgData]);

  if (
    loading ||
    orgLoading ||
    workspaceLoading ||
    rootCausesLoading ||
    tagLoading
  ) {
    return <Loading />;
  }

  const lastItem = currentIndex === total - 1;
  const verbatimStatusLabel = currentVerbatim
    ? intl.formatMessage({
        id: `verbatim.status.${currentVerbatim?.status}`,
      })
    : "";
  const headingText = currentVerbatim
    ? `Verbatim - ${verbatimStatusLabel}`
    : "Verbatim";

  return (
    <Container>
      <VerbatimContainer
        noBorder
        headingText={headingText}
        actions={
          <CardActions>
            <FilterButton
              label="verbatim.status.all"
              primary={activeFilter === ""}
              loading={anyLoading}
              onClick={() => changeFilter("")}
            />
            <FilterButton
              label="verbatim.status.cannot_classify"
              loading={anyLoading}
              primary={activeFilter === VerbatimStatus.CannotClassify}
              onClick={() => changeFilter(VerbatimStatus.CannotClassify)}
            />
          </CardActions>
        }
      >
        <VerbatimText>
          {currentVerbatim?.verbatim ||
            "No verbatims found with the selected filter"}
        </VerbatimText>
      </VerbatimContainer>

      <SidebarContainer noBorder>
        {currentVerbatim && (
          <ClassificationForm
            form={classifyForm}
            verbatim={currentVerbatim}
            rootCauses={rootCausesData.rootCauses}
          />
        )}
        {currentVerbatim && (
          <ActionsContainer>
            <Actions>
              <Button
                loading={anyLoading}
                label="skip.label"
                onClick={() => cannotClassify()}
              />
              <Button label="skip.new.label" onClick={() => skipToNew()} />
            </Actions>
            <Actions>
              <Button
                disabled={currentIndex === 0}
                label="saveAndPrevious.label"
                loading={anyLoading}
                onClick={() => previous()}
              />

              <Button
                primary
                loading={anyLoading}
                label={lastItem ? "save.label" : "saveAndNext.label"}
                onClick={() => (lastItem ? save() : next())}
              />
            </Actions>
            <ProgressBarContainer>
              <ProgressBar
                value={currentIndex + 1}
                max={total}
                width={"100%"}
                color="#ff0000"
              />
            </ProgressBarContainer>
          </ActionsContainer>
        )}
      </SidebarContainer>
    </Container>
  );
};

export default withRouter(Classify);
