import React, { useState } from "react";
import gql from "graphql-tag";
import ExternalShell from "components/ExternalShell";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { login } from "utils/auth";
import Error from "components/Error";
import SubmitButton from "components/FormFields/SubmitButton";
import Form from "components/FormFields/FormContainer";
import PasswordField from "components/FormFields/PasswordField";

const SET_PASSWORD = gql`
  mutation setPassword($input: SetPasswordInput!) {
    setPassword(input: $input) {
      refreshToken
      accessToken
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

type Inputs = {
  password: string;
};

const SetPassword = () => {
  let { code }: any = useParams();
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const [setPasswordMutation, { loading }] = useMutation(SET_PASSWORD, {
    onError: (err) => {
      setPasswordError("generic.error");
    },
    onCompleted: ({ setPassword }) => {
      if (setPassword?.accessToken) {
        login(setPassword);
        window.location.href = "/";
      } else {
        setPasswordError("setPassword.error.invalid.code");
      }
    },
  });

  const { handleSubmit, ...form } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    setPasswordError(null);
    setPasswordMutation({
      variables: {
        input: {
          code: code,
          password: data.password,
        },
      },
    });
  };

  return (
    <ExternalShell>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <PasswordField strengthRequired={true} form={form} />
          {passwordError && <Error error={passwordError} />}

          <SubmitButton loading={loading} label="setPassword.label" />
        </Form>
      </Container>
    </ExternalShell>
  );
};

export default SetPassword;
