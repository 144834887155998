export const ROLE_OPTIONS = [
  {
    value: "user",
    label: "roles.user.label",
  },
  {
    value: "staff",
    label: "roles.staff.label",
  },
  {
    value: "admin",
    label: "roles.admin.label",
  },
];
