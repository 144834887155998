import React from "react";
import { Modal2 } from "sbui";
import { withRouter } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { openInNewTab } from "sbui/utils/opener";

type Inputs = {
  uploadKey: string;
};

const EXPORT_TAGS = gql`
  mutation exportTags($input: ExportTagsInput!) {
    exportTags(input: $input) {
      url
    }
  }
`;

const ExportTagsModal = ({
  isOpen,
  organisationId,
  workspaceId,
  onRequestClose,
}: any) => {
  const [exportTags, { loading }] = useMutation(EXPORT_TAGS, {
    onError: (err) => {
      console.log("err", err);
    },
    onCompleted: ({ exportTags }) => {
      openInNewTab(exportTags.url);
      onRequestClose();
    },
  });

  const onSubmit = async (data: Inputs) => {
    try {
      await exportTags({
        variables: {
          input: {
            workspaceId,
            organisationId,
          },
        },
      });
    } catch (e) {
      console.log("e", e);
    }
  };

  return (
    <Modal2
      titleText="export-tags.heading"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={onSubmit}
      submitLabel="export.label"
      loading={loading}
    >
      <div>
        Export all of the tags + selected verbatim text for this organisation.
      </div>
    </Modal2>
  );
};

export default withRouter(ExportTagsModal);
