import React, { useState } from "react";
import styled from "styled-components";
import Heading2 from "../typography/Heading2";
import Button from "../button/Button";
import IconButton from "../button/IconButton";
import Loading from "../loading";

export type CardProps = {
  children?: any;
  heading?: string;
  headingText?: string;
  className?: string;
  actions?: any;
  collapseable?: boolean;
  defaultOpenValue?: boolean;
  noBorder?: boolean;
  loading?: boolean;
};

export const ContentContainer = styled.div<{ noBorder?: boolean }>`
  background-color: ${(props) => props.theme.page.container.backgroundColor};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: ${(props) => (props.noBorder ? "0px" : "10px")};
  overflow: hidden;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
    border: none;
    box-shadow: none;
    border-radius: 0px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid ${(props) => props.theme.card.borderColor};
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
  }
`;

const CardHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
    margin-bottom: ${(props) => props.theme.spacing.xlarge};
  }
`;

const ActionsContainer = styled.div``;

export const CardActions = styled.div`
  display: flex;
  margin-left: 5px;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
    flex-direction: column;
  }
`;

export const CardButton = styled(Button)`
  margin-right: ${(props) => props.theme.spacing.large};
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
    margin-bottom: ${(props) => props.theme.spacing.large};
    margin-right: 0px;
  }
`;

const CardHeading = styled(Heading2)``;

const CardBody = styled.div`
  /* padding: 10px; */
`;

const StyledContentContainer = styled(ContentContainer)`
  margin-bottom: ${(props) => props.theme.spacing.xlarge};
`;

const Card = ({
  children,
  heading,
  headingText,
  className,
  actions,
  collapseable,
  noBorder,
  loading = false,
  defaultOpenValue = true,
}: CardProps) => {
  const [open, setOpen] = useState(defaultOpenValue);

  const headingElement = heading ? (
    <CardHeading
      onClick={() => collapseable && setOpen(!open)}
      label={heading}
      noMargin
    />
  ) : (
    <CardHeading onClick={() => collapseable && setOpen(!open)} noMargin>
      {headingText}
    </CardHeading>
  );

  return (
    <StyledContentContainer noBorder={noBorder} className={className}>
      {(heading || headingText) && (
        <CardHeader>
          <CardHeadingContainer>
            {collapseable && (
              <IconButton
                name={open ? "arrow_drop_up" : "arrow_drop_down"}
                onClick={() => setOpen(!open)}
              />
            )}
            {headingElement}
          </CardHeadingContainer>
          <ActionsContainer>{actions}</ActionsContainer>
        </CardHeader>
      )}
      {loading && <Loading />}
      {open && <CardBody>{children}</CardBody>}
    </StyledContentContainer>
  );
};

export default Card;
