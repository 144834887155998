import { useMutation } from "@apollo/client";
import Form from "components/FormFields/FormContainer";
import FormTextField from "components/FormFields/FormTextField";
import SubmitButton from "components/FormFields/SubmitButton";
import gql from "graphql-tag";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";
import { Button, Card, Heading3 } from "sbui";
import styled from "styled-components";
import { AnalyseFileResponse, VerbatimRow } from "types";
import AnalyseVerbatimsTable from "./AnalyseVerbatimsTable";
import AllocateWorkspacesModal from "./AllocateWorkspacesModal";

const ANALYSE_S3_FILE = gql`
  mutation analyseS3File($input: AnalyseS3FileInput!) {
    analyseS3File(input: $input) {
      verbatims {
        sourceId
        verbatim
      }
      existingVerbatimCount
      newVerbatimCount
      s3ObjectKey
    }
  }
`;

const Container = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.spacing.large};
  text-align: left;
`;

const AnalysisContainer = styled.div`
  padding: ${(props) => props.theme.spacing.large};
`;

const AnalysisContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing.large};
`;

type AnalyseS3FileFormInput = {
  s3ObjectKey: string;
};

const AllocateWorkspaces = ({ match }: any) => {
  const [error, setError] = useState<string>();
  const organisationId = parseInt(match.params.organisationId);
  const [s3FileAnalysis, setS3FileAnalysis] = useState<AnalyseFileResponse>();
  const [allocateWorkspacesModalOpen, setAllocateWorkspacesModalOpen] =
    useState(false);
  const { handleSubmit, ...form } = useForm<AnalyseS3FileFormInput>();
  const [analyseS3File, { loading }] = useMutation(ANALYSE_S3_FILE, {
    onError: (err) => {
      setError(err.message);
    },
    onCompleted: ({ analyseS3File }) => {
      setS3FileAnalysis(analyseS3File);
    },
  });

  const onSubmit = (data: AnalyseS3FileFormInput) => {
    analyseS3File({
      variables: {
        input: {
          s3ObjectKey: data.s3ObjectKey,
          organisationId: organisationId,
        },
      },
    });
  };

  return (
    <>
      <Card heading="workspaces.allocate.label">
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormTextField name="s3ObjectKey" form={form} />

            <SubmitButton
              label="workspace.allocate.analyse-file"
              loading={loading}
            />
          </Form>
          {error}
          {s3FileAnalysis &&
            s3FileAnalysis.verbatims &&
            s3FileAnalysis.verbatims.length > 0 && (
              <AnalysisContainer>
                {allocateWorkspacesModalOpen && (
                  <AllocateWorkspacesModal
                    isOpen={allocateWorkspacesModalOpen}
                    organisationId={organisationId}
                    verbatims={s3FileAnalysis?.verbatims}
                    maxAllocationTotal={s3FileAnalysis.newVerbatimCount}
                    s3ObjectKey={s3FileAnalysis?.s3ObjectKey}
                    onRequestClose={() => {
                      setAllocateWorkspacesModalOpen(false);
                    }}
                  />
                )}
                <AnalysisContainerHeader>
                  <Heading3>
                    Total number of new records:{" "}
                    {s3FileAnalysis.newVerbatimCount}
                  </Heading3>
                  <Heading3>
                    Total number of existing records:{" "}
                    {s3FileAnalysis.existingVerbatimCount}
                  </Heading3>
                  <Button
                    label="workspaces.allocate.label"
                    primary={true}
                    onClick={() => {
                      setAllocateWorkspacesModalOpen(true);
                    }}
                  />
                </AnalysisContainerHeader>
                <AnalyseVerbatimsTable data={s3FileAnalysis.verbatims} />
              </AnalysisContainer>
            )}
        </Container>
      </Card>
    </>
  );
};

export default withRouter(AllocateWorkspaces);
