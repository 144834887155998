export const fetchMoreHelper = (
  fetchMore: any,
  resultKey: string,
  done: any,
  variables: any
) => {
  fetchMore({
    variables: variables,
    updateQuery: (prev: any, { fetchMoreResult }: any) => {
      done();
      if (!fetchMoreResult) return prev;

      fetchMoreResult[resultKey].items = prev[resultKey].items.concat(
        fetchMoreResult[resultKey].items
      );
      return fetchMoreResult;
    },
  });
};
