import React from "react";
import styled from "styled-components";
import Logo from "components/Logo";
// import Footer from "components/Footer";

const Header = styled.div`
  background-color: ${(props) => props.theme.externalHeader.backgroundColor};
  padding: ${(props) => props.theme.spacing.large};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ChildContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  width: 414px;
  img {
    width: 414px;
  }
`;

const ChildContainerPage = styled.div`
  width: 70vw;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
    flex: 1;
    padding: ${(props) => props.theme.spacing.medium};
  }
  @media only screen and (min-width: ${(props) =>
      props.theme.breakpoints.large}) {
    width: ${(props) => props.theme.page.maxWidth};
  }
`;

export default function ExternalShell(props: any) {
  return (
    <Container>
      <ChildContainer>
        <Header>
          <LogoContainer>
            <Logo />
          </LogoContainer>
        </Header>
        <ChildContainerPage>{props.children}</ChildContainerPage>
      </ChildContainer>
      {/* <Footer /> */}
    </Container>
  );
}
