import { User } from "types";
import AccessLogs from "./AccessLogs";
import Classify from "./Classify";
import Organisation from "./Organisation";
import Organisations from "./Organisations";
import RootCauses from "./RootCauses";
import Tags from "./Tags";
import Users from "./Users";
import Workspace from "./Workspace";
import AllocateWorkspaces from "./AllocateWorkspaces";

const Routes: any = [
  {
    name: "home",
    path: "/",
    redirect: "/organisations",
    exact: true,
    roles: ["user", "staff", "admin"],
    hideFromSidebar: true,
  },
  {
    name: "organisation",
    path: "/organisations/:id",
    component: Organisation,
    exact: true,
    roles: ["user", "staff", "admin"],
    hideFromSidebar: true,
  },
  {
    name: "root-causes",
    path: "/organisations/:id/root-causes",
    component: RootCauses,
    exact: true,
    roles: ["user", "staff", "admin"],
    hideFromSidebar: true,
  },
  {
    name: "workspace",
    path: "/organisations/:organisationId/workspaces/:id",
    component: Workspace,
    exact: true,
    roles: ["user", "staff", "admin"],
    hideFromSidebar: true,
  },
  {
    name: "classify",
    path: "/organisations/:organisationId/workspaces/:id/classify",
    component: Classify,
    exact: true,
    roles: ["user", "staff", "admin"],
    hideFromSidebar: true,
  },
  {
    name: "classify-one",
    path: "/organisations/:organisationId/workspaces/:id/classify/:interactionId",
    component: Classify,
    exact: true,
    roles: ["user", "staff", "admin"],
    hideFromSidebar: true,
  },
  {
    name: "organisations",
    path: "/organisations",
    component: Organisations,
    exact: true,
    roles: ["admin", "staff", "user"],
    hideFromSidebar: false,
  },
  {
    name: "organisation",
    path: "/organisations/:organisationId/allocate-workspaces",
    component: AllocateWorkspaces,
    exact: true,
    roles: ["user", "staff", "admin"],
    hideFromSidebar: true,
  },
  {
    name: "users",
    path: "/users",
    component: Users,
    exact: true,
    roles: ["admin"],
    hideFromSidebar: false,
  },
  {
    name: "accessLogs",
    path: "/access-logs",
    component: AccessLogs,
    exact: true,
    roles: ["admin"],
    hideFromSidebar: false,
  },
  {
    name: "tags",
    path: "/tags",
    component: Tags,
    exact: true,
    roles: ["user", "staff", "admin"],
    hideFromSidebar: false,
  },
];

export const getAvailableRoutes = (user: User) => {
  let routes = Routes.filter((route: any) => route.roles.includes(user?.role));

  return routes;
};
