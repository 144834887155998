import styled from "styled-components";

const Form = styled.form`
  width: 50%;

  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.medium}) {
    width: 75%;
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
    width: 100%;
  }
`;

export default Form;
