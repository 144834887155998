import React from "react";
import styled from "styled-components";

import { useIntl } from "react-intl";

const StyledHeading = styled.h1<{ noMargin?: boolean }>`
  font-size: ${(props) => props.theme.headings.large.fontSize};
  font-weight: regular;
  margin-bottom: ${(props) =>
    props.noMargin ? 0 : props.theme.headings.large.marginBottom};
  margin-top: ${(props) =>
    props.noMargin ? 0 : props.theme.headings.large.marginTop};
`;

export type HeadingProps = {
  label?: string;
  values?: any;
  children?: any;
  noMargin?: boolean;
} & JSX.IntrinsicElements["h1"];

const Heading1 = ({
  label,
  values,
  ref,
  children,
  noMargin = false,
  ...props
}: HeadingProps) => {
  const { formatMessage } = useIntl();

  const intlLabel = children
    ? children
    : formatMessage({ id: label }, values || {});

  return (
    <StyledHeading noMargin={noMargin} ref={ref as any} {...props}>
      {intlLabel}
    </StyledHeading>
  );
};

export default Heading1;
