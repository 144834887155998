import React, { useState } from "react";
import FormTextField from "./FormTextField";
import PasswordStrengthBar from "react-password-strength-bar";

const PasswordField = ({
  name = "password",
  strengthRequired = false,
  form,
  ...rest
}: any) => {
  const [score, setScore] = useState<null | number>(null);
  const password = form.watch(name);

  return (
    <div>
      <FormTextField
        name={name}
        type="password"
        registerArgs={{
          validate: (value: any) => {
            if (!strengthRequired) {
              return null;
            }

            if (strengthRequired && score !== null) {
              if (score < 4) {
                return "password.error.strength";
              }
            }
          },
        }}
        form={form}
        {...rest}
      />
      {strengthRequired && (
        <PasswordStrengthBar
          password={password}
          onChangeScore={(score) => {
            setScore(score);
          }}
        />
      )}
    </div>
  );
};

export default PasswordField;
