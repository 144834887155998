import React, { useEffect } from "react";
import { useTable, useFlexLayout, useRowSelect, useSortBy } from "react-table";
import SortIcon from "@material-ui/icons/Sort";
import TableStyles, { TableRow } from "./TableStyles";
import styled from "styled-components";
import RegularText from "../typography/RegularText";
import { useIntl } from "react-intl";

const SelectableTableRow = ({
  children,
  row,
  table,
  onRowClick,
  selectRowIndex,
  ...props
}: any) => {
  const { checked } = row.getToggleRowSelectedProps();
  const { toggleAllRowsSelected } = table;

  return (
    <TableRow
      {...row.getRowProps()}
      selectable={true}
      checked={selectRowIndex === row.index || checked}
      onClick={() => {
        toggleAllRowsSelected(false);
        row.toggleRowSelected();
        if (onRowClick) {
          onRowClick(row);
        }
      }}
      {...props}
    >
      {children}
    </TableRow>
  );
};

const StandardTableRow = ({ children, row, onRowClick, ...props }: any) => {
  return (
    <TableRow {...row.getRowProps()} {...props}>
      {children}
    </TableRow>
  );
};

const TableHeader = styled.th`
  display: flex;
`;

const SortIconAsc = styled(SortIcon)`
  transform: rotate(180deg);
`;
const SortIconDesc = styled(SortIcon)``;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef: any = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const Table = ({
  columns,
  data,
  onRowClick,
  selectRowIndex,
  onSelect,
  selectable = false,
  multiSelectable = false,
}: any) => {
  const intl = useIntl();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
    ...table
  } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (multiSelectable) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }: any) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }: any) => {
              return (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              );
            },
            width: 1,
          },
          ...columns,
        ]);
      }
    }
  ) as any;

  useEffect(() => {
    if (onSelect && selectedRowIds) {
      onSelect(selectedRowIds);
    }
  }, [onSelect, selectedRowIds]);

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <TableHeader
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.id !== "selection" &&
                  intl.formatMessage({ id: column.render("Header") })}
                {column.id === "selection" && column.render("Header")}
                {column.isSorted &&
                  (column.isSortedDesc ? <SortIconDesc /> : <SortIconAsc />)}
              </TableHeader>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, i: any) => {
          prepareRow(row);

          const RowComponent = selectable
            ? SelectableTableRow
            : StandardTableRow;

          return (
            <RowComponent
              key={row.id ? row.id : row}
              row={row}
              table={table}
              onRowClick={onRowClick}
              selectRowIndex={selectRowIndex}
            >
              {row.cells.map((cell: any) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </RowComponent>
          );
        })}
      </tbody>
    </table>
  );
};

const NoDataContainer = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.spacing.large};
  text-align: left;
  color: #bbb;
`;

const StyledTable = ({ data, ...props }: any) => {
  const hasData = data && data.length > 0;

  return (
    <TableStyles>
      <Table data={data} {...props} />
      {!hasData && (
        <NoDataContainer>
          <RegularText>There are no rows to show.</RegularText>
        </NoDataContainer>
      )}
    </TableStyles>
  );
};

export default StyledTable;
