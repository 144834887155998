import React from "react";

import gql from "graphql-tag";
import { Modal2, RegularText } from "sbui";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { GET_VERBATIMS } from ".";

export const DELETE_VERBATIM = gql`
  mutation deleteVerbatim($input: DeleteVerbatimInput!) {
    deleteVerbatim(input: $input)
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeleteVerbatimModal = ({
  isOpen,
  verbatim,
  workspaceId,
  onRequestClose,
}: any) => {
  const [deleteVerbatim, { loading }] = useMutation(DELETE_VERBATIM, {
    onCompleted: () => {
      onRequestClose();
    },
    refetchQueries: [
      {
        query: GET_VERBATIMS,
        variables: {
          input: {
            workspaceId,
          },
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <Modal2
      titleText="deleteVerbatim.label"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={() => {
        deleteVerbatim({
          variables: {
            input: {
              verbatimId: verbatim.id,
            },
          },
        });
      }}
      loading={loading}
    >
      <Container>
        {`${verbatim.verbatim})`}
        <RegularText label="Are you sure you want to delete this verbatim?" />
      </Container>
    </Modal2>
  );
};

export default DeleteVerbatimModal;
