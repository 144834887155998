import styled, { css } from "styled-components";

const TableStyles = styled.div<{ isSingleColumn?: boolean }>`
  width: 100%;
  table {
    width: 100%;
    border-spacing: 0;
    border: none;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      text-align: center;
      background-color: ${(props) => props.theme.table.header.backgroundColor};
      color: ${(props) => props.theme.table.header.fontColor};
      text-transform: uppercase;
    }

    th,
    td {
      display: flex;
      align-items: center;
      margin: 0;
      padding: ${(props) => props.theme.spacing.large}
        ${(props) => props.theme.spacing.xxlarge};
      border-bottom: 1px solid ${(props) => props.theme.table.colors.border};

      :last-child {
        border-right: 0;
      }

      ${(props) =>
        props.isSingleColumn &&
        css`
          flex-direction: column;
          align-items: flex-start;
        `}
    }
  }
`;

export const TableRow = styled.tr`
  background-color: ${(props: any) => (props.checked ? "#EEE" : "white")};
  cursor: ${(props: any) => (props.selectable ? "pointer" : "default")};
`;

export default TableStyles;
