import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { getAvailableRoutes } from "./routes";

const UserRoutes = ({ user }: any) => {
  const availableRoutes = getAvailableRoutes(user).map((route: any) => {
    return (
      <Route
        key={route.path}
        path={route.path}
        component={
          route.component
            ? route.component
            : () => <Redirect to={route.redirect} />
        }
        exact={route.exact}
      />
    );
  });

  return <Switch>{availableRoutes}</Switch>;
};

export default UserRoutes;
