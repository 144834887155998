import React from "react";
import { Modal2 } from "sbui";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import ExportVerbatimsForm from "./ExportVerbatimsForm";

type Inputs = {
  uploadKey: string;
};

const EXPORT_VERBATIMS = gql`
  mutation exportVerbatims($input: ExportVerbatimsInput!) {
    exportVerbatims(input: $input) {
      url
    }
  }
`;

const openInNewTab = (url: any) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const ExportVerbatimsModal = ({
  isOpen,
  organisationId,
  workspaceId,
  onRequestClose,
}: any) => {
  const { handleSubmit, ...form } = useForm<Inputs>();
  const [exportVerbatims, { loading }] = useMutation(EXPORT_VERBATIMS, {
    onError: (err) => {
      console.log("err", err);
    },
    onCompleted: ({ exportVerbatims }) => {
      openInNewTab(exportVerbatims.url);
      onRequestClose();
    },
  });

  const onSubmit = async (data: Inputs) => {
    try {
      await exportVerbatims({
        variables: {
          input: {
            workspaceId,
            organisationId,
          },
        },
      });
    } catch (e) {
      console.log("e", e);
    }
  };

  return (
    <Modal2
      titleText="export-verbatims.heading"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={handleSubmit(onSubmit)}
      submitLabel="export.label"
      loading={loading}
    >
      <ExportVerbatimsForm
        organisationId={organisationId}
        workspaceId={workspaceId}
        onSubmit={onRequestClose}
        form={form}
      />
    </Modal2>
  );
};

export default withRouter(ExportVerbatimsModal);
