import React from "react";
import styled from "styled-components";

import { useIntl } from "react-intl";

const StyledHeading = styled.h2<{ noMargin?: boolean }>`
  font-size: ${(props) => props.theme.headings.medium.fontSize};
  margin-bottom: ${(props) =>
    props.noMargin ? 0 : props.theme.headings.medium.marginBottom};
  font-weight: ${(props) => props.theme.headings.medium.fontWeight};
`;

export type HeadingProps = {
  label?: string;
  values?: any;
  noMargin?: boolean;
} & JSX.IntrinsicElements["h2"];

const Heading2 = ({
  label,
  values,
  ref,
  children,
  noMargin = false,
  ...props
}: HeadingProps) => {
  const { formatMessage } = useIntl();

  const intlLabel = !children
    ? formatMessage({ id: label }, values || {})
    : children;

  return (
    <StyledHeading noMargin={noMargin} ref={ref as any} {...props}>
      {intlLabel}
    </StyledHeading>
  );
};

export default Heading2;
