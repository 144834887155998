import React from "react";

import gql from "graphql-tag";
import { Modal2, RegularText } from "sbui";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { GET_USERS } from "./index";

export const KICK_SESSIONS = gql`
  mutation kickSessions($input: KickSessionsInput!) {
    kickSessions(input: $input)
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const KickSessionsModal = ({ isOpen, user, onRequestClose }: any) => {
  const [kickSessions, { loading }] = useMutation(KICK_SESSIONS, {
    onCompleted: () => {
      onRequestClose();
    },
    refetchQueries: [
      {
        query: GET_USERS,
      },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <Modal2
      titleText="kickSessions.label"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={() => {
        kickSessions({
          variables: {
            input: {
              userId: user.id,
            },
          },
        });
      }}
      loading={loading}
    >
      <Container>
        {`${user.firstName} ${user.lastName} (${user.email})`}
        <RegularText label="Are you sure you want to kick this user from all logged in sessions?" />
      </Container>
    </Modal2>
  );
};

export default KickSessionsModal;
