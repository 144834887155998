import React from "react";
import ReactModal from "react-modal";
import { Heading1, IconButton, Button } from "../../";
import styled from "styled-components";

function ReactModalAdapter({ className, ...props }: any) {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  );
}

const StyledModal = styled(ReactModalAdapter)`
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;

    z-index: ${(props) => props.theme.zIndexModal};

    background-color: rgba(0, 0, 0, 0.5);
  }

  &__content {
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    padding: ${(props) => props.theme.spacing.xxlarge};

    min-height: 60vh;
    max-width: ${(props) => props.theme.breakpoints.large};
    margin-top: 30px;
    margin-bottom: 30px;

    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);

    background-color: white;

    @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
      min-height: 100vh;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`;

const ModalBody = styled.div`
  flex-grow: 1;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Modal = ({
  isOpen,
  onRequestClose,
  onCancel,
  onSubmit,
  titleText,
  children,
  disableSubmit,
  loading,
  submitLabel,
  cancelLabel,
}: any) => {
  ReactModal.setAppElement("#root");
  return (
    <StyledModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <HeadingContainer>
        <Heading1 label={titleText} />
        <IconButton name="close" onClick={onRequestClose} />
      </HeadingContainer>
      <ModalBody>{children}</ModalBody>
      <FooterContainer>
        {onRequestClose && (
          <Button
            label={cancelLabel || "generic.cancel.label"}
            onClick={onCancel || onRequestClose}
          />
        )}
        {onSubmit && (
          <Button
            type="button"
            className="t-modal-submit-button"
            label={submitLabel || "generic.submit.label"}
            primary
            onClick={onSubmit}
            disabled={disableSubmit || loading}
            loading={loading}
          />
        )}
      </FooterContainer>
    </StyledModal>
  );
};

export default Modal;
