import React from "react";
import styled from "styled-components";

import { useIntl } from "react-intl";

const StyledHeading = styled.h3`
  font-size: ${(props) => props.theme.headings.small.fontSize};
  margin-bottom: ${(props) => props.theme.headings.small.marginBottom};
`;

export type HeadingProps = {
  label?: string;
  values?: any;
} & JSX.IntrinsicElements["h3"];

const Heading3 = ({ label, values, ref, children, ...props }: HeadingProps) => {
  const { formatMessage } = useIntl();

  const intlLabel =
    !children && label ? formatMessage({ id: label }, values || {}) : children;

  return (
    <StyledHeading ref={ref as any} {...props}>
      {intlLabel}
    </StyledHeading>
  );
};

export default Heading3;
