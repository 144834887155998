import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import Color from "color";
import ReactTooltip from "react-tooltip";

export type StyledButtonProps = {
  primary?: boolean;
};

const StyledButton = styled.button<StyledButtonProps>`
  white-space: nowrap;
  border: ${(props) =>
    props.primary ? "none" : `1px solid ${props.theme.button.border.color}`};
  color: ${(props) =>
    props.primary
      ? props.theme.button.colors.primary.text
      : props.theme.button.colors.secondary.text};
  background-color: ${(props) =>
    props.primary
      ? props.theme.button.colors.primary.background
      : props.theme.button.colors.secondary.background};
  padding: ${(props) => props.theme.button.padding};
  border-radius: ${(props) => props.theme.button.border.radius};
  font-size: ${(props) => props.theme.button.font.default.size};

  &:hover:enabled {
    cursor: pointer;
    background-color: ${(props) =>
      props.primary
        ? Color(props.theme.button.colors.primary.background).darken(0.1).hex()
        : Color(props.theme.button.colors.secondary.background)
            .darken(0.1)
            .hex()};
  }

  text-transform: uppercase;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

export type ButtonProps = {
  label: string;
  values?: any;
  primary?: boolean;
  loading?: boolean;
  className?: string;
  tooltip?: string;
} & JSX.IntrinsicElements["button"];

const Button = ({
  label,
  values,
  ref,
  tooltip,
  primary = false,
  loading = false,
  className,
  ...props
}: ButtonProps) => {
  const { formatMessage } = useIntl();
  const text = formatMessage(
    { id: loading ? "generic.loading" : label },
    values
  );

  return (
    <StyledButton
      className={className}
      ref={ref as any}
      primary={primary}
      data-test={label}
      disabled={loading}
      {...props}
    >
      <span data-tip={tooltip} data-tip-disable={false}>
        {text}
      </span>

      <ReactTooltip />
    </StyledButton>
  );
};

export default Button;
