import { useQuery } from "@apollo/client";
import { useBreadcrumbsDispatch } from "components/Breadcrumbs";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Card, Heading1, CardActions, CardButton } from "sbui";
import Loading from "sbui/components/loading";
import { GET_ROOT_CAUSES } from "../Classify";
import { GET_ORGANISATION } from "../Organisation";
import MergeReferenceDataModal from "./MergeReferenceDataModal";
import MoveEnquiriesModal from "./MoveEnquiriesModal";
import MoveSubEnquiriesModal from "./MoveSubEnquiriesModal";
import RootCausesTree from "./RootCausesTree";

export const GET_REFERENCE_DATA_VERBATIM_COUNT = gql`
  query referenceDataVerbatimCount($organisationId: Float!) {
    referenceDataVerbatimCount(organisationId: $organisationId) {
      count
      referenceDataId
      referenceDataType
    }
  }
`;

const RootCauses = ({ match, history }: any) => {
  const [selectedReferenceData, setSelectedReferenceData] = useState([]);
  const [mergeRootCauseModal, setMergeRootCauseModal] = useState(false);
  const [moveEnquiriesModal, setMoveEnquiriesModal] = useState(false);
  const [moveSubEnquiriesModal, setMoveSubEnquiriesModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const [rootCauseItemState, setRootCauseItemState] = useState({});
  const [enquiryItemState, setEnquiryItemState] = useState({});

  const { loading, data } = useQuery(GET_ORGANISATION, {
    variables: {
      organisationId: parseInt(match.params.id),
    },
  });

  const { loading: rootCauseLoading, data: rootCauseData } = useQuery(
    GET_ROOT_CAUSES,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          organisationId: parseInt(match.params.id),
        },
      },
    }
  );

  const {
    loading: refDataVerbatimCountLoading,
    data: refDataVerbatimCountData,
  } = useQuery(GET_REFERENCE_DATA_VERBATIM_COUNT, {
    variables: {
      organisationId: parseInt(match.params.id),
    },
  });

  const showMerge = () => {
    setSelectedReferenceData(selectedItems);
    setMergeRootCauseModal(true);
  };

  const showMove = () => {
    setSelectedReferenceData(selectedItems);
    setMoveEnquiriesModal(true);
  };

  const showMoveSubEnquiries = () => {
    setSelectedReferenceData(selectedItems);
    setMoveSubEnquiriesModal(true);
  };

  const onChangeHandler = (e: any) => {
    const nameParts = e.target.name.split(":");
    const type = nameParts[0];
    const id = nameParts[1];

    if (e.target.checked) {
      // add
      setSelectedItems([
        ...selectedItems,
        {
          type,
          id,
        },
      ]);
    } else {
      const updatedItems = selectedItems.filter(
        (item: any) => !(item.type === type && item.id === id)
      );
      setSelectedItems(updatedItems);
    }
  };

  const atLeastTwoSelected = selectedItems.length >= 2;
  const selectedTypes = Object.keys(
    selectedItems.reduce((acc: any, value: any) => {
      acc[value.type] = true;
      return acc;
    }, {})
  );

  const parentMap: any = {};
  (rootCauseData?.rootCauses || []).forEach((rc: any) => {
    rc.enquiries.forEach((en: any) => {
      parentMap[`enquiry:${en.id}`] = `rootCause:${rc.id}`;
      en.subEnquiries.forEach((se: any) => {
        parentMap[`subEnquiry:${se.id}`] = `enquiry:${en.id}`;
      });
    });
  });

  const singleTypeSelected = selectedTypes.length === 1;
  const selectedType = singleTypeSelected ? selectedTypes[0] : null;
  const uniqueParents = selectedItems.reduce((acc: any, value: any) => {
    acc[parentMap[`${value.type}:${value.id}`]] = true;
    return acc;
  }, {});

  const uniqueParentsCount = Object.keys(uniqueParents).length;
  const wrongParents = selectedType !== "rootCause" && uniqueParentsCount > 1;

  const mergeDisabled = !(
    atLeastTwoSelected &&
    singleTypeSelected &&
    !wrongParents
  );

  const moveEnabled = selectedType === "enquiry" && uniqueParentsCount === 1;
  const moveSubEnquiriesEnabled = selectedType === "subEnquiry";

  const dispatch = useBreadcrumbsDispatch();
  useEffect(() => {
    if (data) {
      dispatch({
        type: "setBreadcrumbs",
        value: [
          {
            label: "organisations.heading",
            to: "/organisations",
          },
          {
            label: data.organisation.name,
            to: `/organisations/${data.organisation.id}`,
          },
          {
            label: "rootCauses.heading",
          },
        ],
      });
    }
  }, [dispatch, data]);

  if (loading || rootCauseLoading || refDataVerbatimCountLoading) {
    return <Loading />;
  }

  return (
    <>
      {mergeRootCauseModal && (
        <MergeReferenceDataModal
          isOpen={mergeRootCauseModal}
          type={selectedType}
          organisationId={parseInt(match.params.id)}
          referenceData={selectedReferenceData}
          rootCauses={rootCauseData.rootCauses}
          onRequestClose={(success: boolean) => {
            if (success === true) {
              setSelectedItems([]);
            }

            setMergeRootCauseModal(false);
          }}
        />
      )}
      {moveEnquiriesModal && (
        <MoveEnquiriesModal
          isOpen={moveEnquiriesModal}
          organisationId={parseInt(match.params.id)}
          referenceData={selectedReferenceData}
          rootCauses={rootCauseData.rootCauses}
          onRequestClose={(success: boolean) => {
            if (success === true) {
              setSelectedItems([]);
            }

            setMoveEnquiriesModal(false);
          }}
        />
      )}
      {moveSubEnquiriesModal && (
        <MoveSubEnquiriesModal
          isOpen={moveSubEnquiriesModal}
          organisationId={parseInt(match.params.id)}
          referenceData={selectedReferenceData}
          rootCauses={rootCauseData.rootCauses}
          onRequestClose={(success: boolean) => {
            if (success === true) {
              setSelectedItems([]);
            }

            setMoveSubEnquiriesModal(false);
          }}
        />
      )}
      <Heading1>{data.organisation.name} - Root Causes</Heading1>
      <Card
        heading="rootCauses.heading"
        actions={
          <CardActions>
            <CardButton
              label="move.label"
              onClick={() => showMove()}
              disabled={!moveEnabled}
            />
            <CardButton
              label="move-sub-enquiries.label"
              onClick={() => showMoveSubEnquiries()}
              disabled={!moveSubEnquiriesEnabled}
            />
            <CardButton
              label="merge.label"
              onClick={() => showMerge()}
              disabled={mergeDisabled}
            />
          </CardActions>
        }
      >
        <RootCausesTree
          data={rootCauseData?.rootCauses || []}
          onChangeHandler={onChangeHandler}
          referenceDataVerbatimCount={
            refDataVerbatimCountData?.referenceDataVerbatimCount || []
          }
          enquiryItemState={enquiryItemState}
          rootCauseItemState={rootCauseItemState}
          setEnquiryItemState={setEnquiryItemState}
          setRootCauseItemState={setRootCauseItemState}
        />
      </Card>
    </>
  );
};

export default withRouter(RootCauses);
