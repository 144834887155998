import React from "react";
import { Select } from "sbui";
import { useIntl } from "react-intl";
import { Controller } from "react-hook-form";
import _ from "lodash";

export type SelectFieldProps = {
  name: string;
  form: any;
  options: any;
  loading?: boolean;
  label?: string;
  creatable?: boolean;
  helperText?: string;
  isIntl?: boolean;
  required?: boolean;
  onCreateOption?: any;
  onChangeHook?: any;
  tooltip?: string;
  isClearable?: boolean;
  containerClassName?: string;
  isMulti?: boolean;
  errorSelector?: string;
} & JSX.IntrinsicElements["select"];

export const SelectField = ({
  form,
  name,
  options,
  loading,
  required = true,
  isIntl = true,
  isClearable,
  errorSelector,
  ...outerProps
}: SelectFieldProps) => {
  const intl = useIntl();
  const label = outerProps.label || `${name}.label`;
  const className = outerProps.className || `t-${name}-field`;

  const {
    control,
    formState: { errors, touchedFields, isSubmitted },
  } = form;

  options = options.map((option: any) => {
    return {
      ...option,
      label:
        isIntl && option.label
          ? intl.formatMessage({ id: option.label }, {})
          : option.label,
    };
  });

  const selectPlaceholder = outerProps.creatable
    ? "generic.select.create"
    : "generic.select";

  const error = errorSelector ? _.get(errors, errorSelector) : errors[name];

  return (
    <Controller
      name={name}
      rules={{ required }}
      control={control}
      render={({ field }) => {
        return (
          <Select
            {...field}
            name={name}
            isClearable={isClearable}
            touched={touchedFields[name] || isSubmitted}
            error={error && "generic.error.required"}
            className={className}
            placeholder={loading ? "generic.loading" : selectPlaceholder}
            label={label || `${name}.label`}
            options={options}
            {...outerProps}
          />
        );
      }}
    />
  );
};
